import { UserAgentApplication, Logger, LogLevel } from "msal";

export const deployPostMethod = async (url, accessToken, template) => {

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(template),
    });

    return response.json();
};

export const fetchMsResourceGroup = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    });

    return response.json();
};

export const deployTemplate = async (url, accessToken, template) => {
    const response = await fetch(url, {
        method: "put",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(template),
    });
    return response.json();
};


export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    USER_IMPERSONATION: "https://management.azure.com/user_impersonation",
    APPLICATION_READ: "Application.Read.All",
    DIRECTORY_READ: "Directory.Read.All",
    USER_INVITE:"User.Invite.All"
    // USER_IMPERSONATION: "api://a42ea19a-7c15-41ea-8276-215aa58c0f17/user_impersonation",
    // USER_IMPERSONATION: "user_impersonation",
};

export const GRAPH_REQUESTS = {
    LOGIN: (msService = "general") => {
        let loginScope = {
            scopes: [
                GRAPH_SCOPES.OPENID,
                GRAPH_SCOPES.PROFILE,
                GRAPH_SCOPES.USER_READ,
                msService !== "us_gov" ?
                    GRAPH_SCOPES.USER_IMPERSONATION :
                    GRAPH_SCOPES.USER_IMPERSONATION.replace("azure.com", "usgovcloudapi.net"),
                GRAPH_SCOPES.APPLICATION_READ,
                GRAPH_SCOPES.DIRECTORY_READ,
                //  GRAPH_SCOPES.USER_INVITE
            ],
            prompt: "select_account"
        };
        if(msService === "us_gov"){
            loginScope["login_hint"] = "onmicrosoft.us"
        }
        return loginScope;
    },
    ACTIVE_DIRECTORY: {
        scopes: [GRAPH_SCOPES.USER_IMPERSONATION]
    },
    AZURE_SCOPE: (msService = "general") => ({
        scopes: [
            msService !== "us_gov" ?
                GRAPH_SCOPES.USER_IMPERSONATION :
                GRAPH_SCOPES.USER_IMPERSONATION.replace("azure.com", "usgovcloudapi.net"),
        ]
    }),
    PROFILE: (msService = "general") => ({
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ,
            msService !== "us_gov" ?
                GRAPH_SCOPES.USER_IMPERSONATION :
                GRAPH_SCOPES.USER_IMPERSONATION.replace("azure.com", "usgovcloudapi.net"),
            GRAPH_SCOPES.APPLICATION_READ,
            GRAPH_SCOPES.DIRECTORY_READ
        ],
    }),
};

export const msalApp = (tenant = "common", msService = "general") => {
    return new UserAgentApplication({
        auth: {
            clientId: "a42ea19a-7c15-41ea-8276-215aa58c0f17", // only for AD Users
            // clientId: "f7e61760-2a02-4d3a-9161-26dc6dee937f", // All MS Users
            authority: `https://login.microsoftonline.${msService === "general" ? "com" : "us"}/${tenant === undefined || tenant === "" ? "common" : tenant}`,
            validateAuthority: true,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: isIE(),
        },
        system: {
            navigateFrameWait: 1,
            logger: new Logger((logLevel, message) => {
                console.log(message);
            }, {
                level: LogLevel.Error,
                piiLoggingEnabled: false
            }),
        }
    });
};
