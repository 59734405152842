import axios from 'axios';
import { store } from './modules/redux/store';
import { AuthActions } from './modules/redux';
import { AuthState } from "./modules/redux/Auth/reducer";

export function GetSignupAllowedDomain() {
}


export function GetSignupConfig() {
}


export const CurrencySymbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
};

export const GetGraphToken = () => {
    let check = store.getState();
    let auth = check.auth;
    return auth.token;
}

export const GetMsService = () => {
    let check = store.getState();
    let appSettings = check.appSettings;
    return appSettings.msService;
}

export const GetAzureToken = () => {
    let check = store.getState();
    let auth = check.auth;
    return auth.azureToken;
}


export const GetAWSAuth = () => {
    let check = store.getState();
    let auth = check.auth;
    if (auth.provider === "aws-auth")
        return auth["aws-auth"];
    else
        return "";
}

export const Endpoint = {
    Organization: "/api/org",
    User: "/api/user",
    Role: "/api/role",
    BlueprintModel: "/api/blueprint",
    Image: "/api/image",
    Extension: "/api/extension",
    DeploymentList: "/api/azure/deployment.get.user",
    Deployment: "/api/user/deployment",
    ResourceManagement: "/api/resource",
    UserSubscription: "/api/user/subscription",
    MailTemplate: "/api/mailtemplate",
    InviteUser: "/api/invite/user",
    NameCheck: "/api/namecheck",
    GitHub: "/api/user/github",
    PbiDashboard: "/api/user/pbi",
    Directory: "/api/user/directory",
    CostManagement: "/api/costManagement",
    ProviderConfig: "/api/portal/provider/{provider}/config",
    Notification: "/api/upload/notification",
    SqlDb: "/api/database/sql/tables",
    ExploreData:"/api/database-explorer",
    Database: "/api/database",
    Gallery: "/api/azure/gallery",
    UpdateResourceAuthorization: "/api/resource/authorization",
    AppAvailability: "/api/app/availability",
    DataLake: "/api/datalake",
    RequestTrial: "/api/trail/request",
    AWS: "/api/aws",
    StorageAccount: "/api/user/storageAccount",
    Config: "/api/portal/config",
    FunctionApp: "/api/functionApp",
    Learn: "/api/learn",
    FunctionAppForm: "/api/functionapp/form",
    TemplateType: "/api/type/blueprint",
    TemplateField: "/api/field",
    TemplateForm: "/api/form",
    OpenAi: "/api/openai",
    Converter: "/api/converter",
    WebSettings: "/api/web-settings/admin",
    Location: "/api/web-settings/admin/web/location",
    Careers: "/api/web-settings/admin/web/careers",
}

export const FIELD_TYPE = {
    "TEXT_FIELD": 1,
    "TEXT_AREA": 2,
    "SELECT": 3,
    "MULTI_SELECT": 4,
    "DATE": 5,
    "TITLE": 6,
    "SUB_TITLE": 7,
    "DIVIDER": 8,
    "INFORMATION": 9,
    // "SELECT_NESTED": 10,
    // "SELECT_AUTO_COMPLETE": 11,
    // "FORM": 12,
    "NUMBER": 13
};
export const HttpMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
    OPTIONS: "OPTIONS"
}

export const Service = {
    Method: {
        Get: "GET",
        Post: "POST",
        Put: "PUT",
        Delete: "DELETE",
        Patch: "PATCH",
        Options: "OPTIONS"
    },
    Request: {
        Post: (endpoint, data, token, azureToken = "") => API(token, azureToken).post(endpoint, data),
        Put: (endpoint, data, token, azureToken = "") => API(token, azureToken).put(endpoint, data),
        Get: (endpoint, token, azureToken = "") => API(token, azureToken).get(endpoint),
        Delete: (endpoint, token, azureToken = "") => API(token, azureToken).delete(endpoint)
    }
}

export const Blueprint = {
    Type: {
        List : [
            { key: "simple", text: "Simple" },
            { key: "batch", text: "Batch" },
            { key: "nested", text: "Nested" },
            { key: "hpc-cache", text: "HPC Cache" },
            { key: "dataingest", text: "Data Ingest" }, 
            /*{ key: "powerBi", text:"Power BI"},*/
            { key: 'function_app', text: 'Function App' },
            { key: 'storage', text: 'Storage' }, 
            { key: "data-lake", text: "Data Lake" },
            { key: "cognitiveservices", text: "Cognitive Services" },
            { key: "nist-simple", text: "NIST SP 800-171 Rev. 2 - Simple" },
            { key: "nist-storage", text: "NIST SP 800-171 Rev. 2 - Storage" },
        ],
        Keys : {
            Simple : "simple",
            Batch : "batch",
            Nested : "nested",
            HPCCache : "hpc-cache",
            DataIngest : "dataingest",
            FunctionApp : "function_app",
            PowerBI : "powerbi",
            Storage : "storage",
            DataLake : "data-lake",
            CognitiveServices : "cognitiveservices",
            NISTSimple : "nist-simple",
            NISTStorage : "nist-storage" 
        }
    }
};

export const API = (token, azureToken) => axios.create({
    baseURL: window.location.origin,
    headers: {
        "Authorization": "Bearer " + (token === "" ? GetGraphToken() : token),
        "AzureAuthorization": "Bearer " + (azureToken === "" ? GetAzureToken() : azureToken),
        "aws-auth": GetAWSAuth(),
        "msService": GetMsService()
    }
});

export const ListQuery = {
    filter: "",
    skip: 0,
    limit: 0,
    from: 0,
    to: new Date().getTime(),
    sortObj: {},
    filterObj: [],
    fields: "",
    tenantId:""
};

export const ListResult = {
    count: 0,
    total: 0,
    result: []
}

export const GetMicrosoftConfig = async () => await Service.Request.Get(Endpoint.ProviderConfig.replace("{provider}", "microsoft"), "", "");

export const GetGitHubConfig = async () => await Service.Request.Get(Endpoint.ProviderConfig.replace("{provider}", "github"), "", "");

export const GetProvider = async () => await Service.Request.Get(Endpoint.Config + "/provider/list");

export const GetChannelCode = async () => await Service.Request.Get(Endpoint.Config + "/channelcode");

export const GetChannelResource = async () => await Service.Request.Get(Endpoint.Config + "/channelresource");
export const GetAllowedMsService = async () => await Service.Request.Get(Endpoint.Config + "/allowed-ms-service");

export const GetAdminEmail = async () => await Service.Request.Get(Endpoint.Config + "/adminEmail");


export const Credentials = {
    GitHub: {
        Config: GetGitHubConfig(),
        LoginUrl: "https://github.com/login/oauth/authorize?client_id={clientId}&redirect_uri={redirectUrl}&scope=repo",
        RedirectUrl: window.location.origin + "/api/user/login/social/github/{email}"
    }
};

export const GitHubLoginUrl = (configData = {}) => {
    return Credentials.GitHub.LoginUrl.replace("{clientId}", configData?.data?.clientId)
        .replace("{redirectUrl}", Credentials.GitHub.RedirectUrl).replace("&amp;", "&");
};

// Create, Delete and Update Response
export const DataResult = {
    success: true,
    message: "",
    data: ""
}

export const OrgMailTemplateKey = [
    {
        key: 0,
        name: "Invite User"
    },
    {
        key: 1,
        name: "Deployment Success"
    },
    {
        key: 2,
        name: "Deployment Fail"
    },
    {
        key: 3,
        name: "Upload Notification"
    },
]

export const Modules = [
    {
        value: 'mxraas',
        label: 'MxRaaS',
        children: [
            {
                value: 'administration',
                label: 'Administration',
                children: [
                    {
                        value: 'organization',
                        label: 'Organization',
                        children: [
                            { value: 'organizationList', label: 'List' },
                            { value: 'organizationCreate', label: 'Create' },
                            { value: 'organizationEdit', label: 'Edit' },
                            { value: 'organizationDelete', label: 'Delete' },
                            { value: 'organizationView', label: 'View' },
                            { value: 'mailTemplatePreview', label: 'Mail Template Preview' },
                            { value: 'organizationPermissionView', label: 'Permission View' },
                            { value: 'organizationPermissionEdit', label: 'Permission Edit' }
                        ]
                    },
                    {
                        value: 'role',
                        label: 'Role',
                        children: [
                            { value: 'roleList', label: 'List' },
                            { value: 'roleCreate', label: 'Create' },
                            { value: 'roleEdit', label: 'Edit' },
                            { value: 'roleDelete', label: 'Delete' },
                            { value: 'roleView', label: 'View' },
                            { value: 'rolePermissionView', label: 'Permission View' },
                            { value: 'rolePermissionEdit', label: 'Permission Edit' }
                        ]
                    },
                    {
                        value: 'user',
                        label: 'User',
                        children: [
                            { value: 'userList', label: 'List' },
                            { value: 'userCreate', label: 'Create' },
                            { value: 'userEdit', label: 'Edit' },
                            { value: 'userDelete', label: 'Delete' },
                            { value: 'userView', label: 'View' },
                            { value: 'userInvite', label: 'Invite' },
                        ]
                    },
                    {
                        value: 'blueprint',
                        label: 'Blueprint',
                        children: [
                            { value: 'blueprintList', label: 'List' },
                            { value: 'blueprintCreate', label: 'Create' },
                            { value: 'blueprintEdit', label: 'Edit' },
                            { value: 'blueprintDelete', label: 'Delete' },
                            { value: 'blueprintView', label: 'View' },
                            {
                                value: 'image',
                                label: 'Image',
                                children: [
                                    { value: 'imageList', label: 'List' },
                                    { value: 'imageCreate', label: 'Create' },
                                    { value: 'imageEdit', label: 'Edit' },
                                    { value: 'imageDelete', label: 'Delete' },
                                    { value: 'imageView', label: 'View' },
                                ]
                            },
                            {
                                value: 'extension',
                                label: 'Extension',
                                children: [
                                    { value: 'extensionList', label: 'List' },
                                    { value: 'extensionCreate', label: 'Create' },
                                    { value: 'extensionEdit', label: 'Edit' },
                                    { value: 'extensionDelete', label: 'Delete' },
                                    { value: 'extensionView', label: 'View' },
                                ]
                            }
                        ]
                    },
                    {
                        value: 'subscription',
                        label: 'Subscription',
                        children: [
                            { value: 'subscriptionList', label: 'Subscription List' },
                            {
                                value: 'resourceGroup',
                                label: 'Resource Group',
                                children: [
                                    { value: 'resourceGroupList', label: 'List' },
                                    { value: 'resourceGroupCreate', label: 'Create' },
                                    { value: 'resourceGroupEdit', label: 'Edit' },
                                    { value: 'resourceGroupDelete', label: 'Delete' },
                                    { value: 'resourceGroupView', label: 'View' },
                                    { value: 'accessControl', label: 'Access Control' }
                                ]
                            },
                            {value: 'insightsService', label: 'Insights'},
                            {value: 'metrics', label: 'Metrics'}
                        ]
                    },
                    {
                        value: 'policy',
                        label: 'Policy',
                        children: [
                            {value: 'policyAssign', label: 'Policy Assignment' },
                            {value: 'complianceResource', label: 'Compliance Resource'},
                            {value: 'policyDefinition', label: 'Policy Definition'},
                            {value: 'policyReport', label: 'Policy Report'},
                        ]
                    },
                    {
                        value: 'optimizer',
                        label: 'Optimizer',
                        children: [
                            { value: 'costRecommendation', label: 'Cost' },
                            {value: 'securityRecommendation', label: 'Security'},
                            {value: 'highAvailabilityRecommendation', label: 'High Availability'},
                            {value: 'operationalExcellenceRecommendation', label: 'Operational Excellence'},
                            {value: 'performanceRecommendation', label: 'Performance'},
                        ]
                    }
                ]
            },
            {
                value: 'marketplace',
                label: 'Marketplace',
            },
            {
                value: 'blueprints',
                label: 'Blueprints',
            },
            {
                value: 'learn',
                label: 'Learn',
                children: [
                    { value: 'learnCreate', label: 'Create' },
                    { value: 'learnList', label: 'List' },
                    { value: 'learnEdit', label: 'Edit' },
                    { value: 'learnView', label: 'View' },
                    { value: 'learnDelete', label: 'Delete' }
                ]
            },
            {
                value: 'templateConverter',
                label: 'Converter',
                children: [
                    { value: 'converter', label: 'Converter' },
                    { value: 'convertedListCreate', label: 'Create' },
                    { value: 'convertedList', label: 'List' },
                    { value: 'convertedListEdit', label: 'Update' },
                    { value: 'convertedListDelete', label: 'Delete' }
                ]
            },
            {
                value: 'deployment',
                label: 'Deployment',
                children: [
                    { value: 'blueprintProvision', label: 'Blueprint Provision' },
                    {
                        value: 'deploymentActions',
                        label: 'Deployment Actions',
                        children: [
                            { value: 'deploymentList', label: 'Deployment List' },
                            { value: 'resourceManagement', label: 'Resource Management' },
                            { value: 'addDeployment', label: 'Assign' },
                            { value: 'downloadDeployment', label: 'Download' },
                            { value: 'deleteDeployment', label: 'Delete' },
                            { value: 'shareDeployment', label: 'Share' }
                        ]
                    },
                ]
            },
            {
                value : "contentmanagementsystem",
                label : "Content Management System",
                children : [{
                    value : "contentmanagementsystem", label : "Content Management System"
                }]
            },
            {
                value: 'costManagement',
                label: 'Cost Management',
                children: [
                    { value: 'costUsageLimit', label: 'Cost Usage Limit' },
                    { value: 'costAnalysis', label: 'Cost Analysis' },
                ]
            },
            {
                value: 'resource',
                label: 'Resource',
                children: [
                    {
                        value: 'resource',
                        label: 'Resource',
                        children: [
                            { value: 'portalVmList', label: 'Portal VM List' },
                            { value: 'azureVmList', label: 'Azure VM List' },
                            { value: 'vmInsights', label: 'VM Insights' },
                        ]
                    },
                    {
                        value: 'powerBiDashboard',
                        label: 'Power BI Dashboard',
                        children: [
                            { value: 'powerBiDashboardList', label: 'List' },
                            { value: 'powerBiDashboardCreate', label: 'Create' },
                            { value: 'powerBiDashboardEdit', label: 'Edit' },
                            { value: 'powerBiDashboardView', label: 'View' },
                            { value: 'powerBiDashboardDelete', label: 'Delete' },
                        ]
                    },
                    {
                        value: 'exploreData', label: 'Explore Data',
                        children: [
                            { value: 'exploreDataList', label: 'List' },
                            { value: 'exploreDataCreate', label: 'Create' },
                            { value: 'exploreDataEdit', label: 'Edit' },
                            { value: 'exploreDataShare', label: 'Share' },
                            { value: 'exploreDataView', label: 'View' },
                            { value: 'exploreDataDelete', label: 'Delete' },
                            { value: 'exploreDataQueryEditor', label: 'Query Editor' },
                            { value: 'exploreDataQueryFilter', label: 'Query Filter' },
                            {
                                value: 'exploreDataDownload', label: 'Data Download',
                                children: [
                                    { value: 'exploreDataDownloadCsv', label: 'CSV' },
                                    { value: 'exploreDataDownloadJson', label: 'JSON' },
                                ]
                            },
                        ]
                    },
                    {
                        value: 'storageAccount',
                        label: 'Storage Account',
                        children: [
                            { value: 'storageAccountList', label: 'List' },
                            { value: 'storageAccountCreate', label: 'Create' },
                            { value: 'storageAccountView', label: 'View' },
                            { value: 'storageAccountUpload', label: 'Upload' },
                            { value: 'storageAccountDelete', label: 'Delete' },
                            { value: 'storageAccountInsights', label: 'Insights' },
                            { value: 'storageAccountTable', label: 'Table' }
                        ]
                    },
                    {
                        value: 's3Bucket',
                        label: 'S3 Bucket',
                        children: [
                            { value: 's3BucketList', label: 'List' },
                            { value: 's3BucketCreateFolder', label: 'Create Folder' },
                            { value: 's3BucketUpload', label: 'Upload' },
                            { value: 's3BucketDelete', label: 'Delete' },
                        ]
                    },
                    {
                        value: 'dataLake',
                        label: 'Data Lake',
                        children: [
                            { value: 'dataLakeCreateFolder', label: 'Create Folder' },
                            { value: 'dataLakeUpload', label: 'Upload' },
                            { value: 'dataLakeExplore', label: 'Explore' },
                            { value: 'dataLakeAccess', label: 'Access' },
                            { value: 'dataLakeDelete', label: 'Delete' },
                        ]
                    },
                    {
                        value: 'functionApp',
                        label: 'Function App',
                        children: [
                            { value: 'functionAppList', label: 'List' },
                            {
                                value: 'appSettings',
                                label: 'App Settings',
                                children: [
                                    { value: 'appSettingsAdd', label: 'Add' },
                                    { value: 'appSettingsEdit', label: 'Edit' },
                                    { value: 'appSettingsList', label: 'List' },
                                    { value: 'appSettingsDelete', label: 'Delete' },
                                ]
                            },
                            { value: 'deploymentCenter', label: 'Deployment Center' },
                        ]
                    },
                    {
                        value: 'form',
                        label: 'Form',
                        children: [
                            { value: 'formList', label: 'List' },
                            { value: 'formCreate', label: 'Create' },
                            { value: 'formEdit', label: 'Edit' },
                            { value: 'formShare', label: 'Share'},
                            { value: 'formRun', label: 'Run' },
                            { value: 'formDelete', label: 'Delete' },
                        ]
                    },
                ]
            },
            {
                value: 'websiteSettings',
                label: 'Website Settings',
            },
        ]
    }
];


export const UserPermissionModules = () => {

    let check = store.getState();
    let permission = check.auth.account.orgInfo.permission;
    let TempModule = [
        {
            value: 'mxraas',
            label: 'MxRaaS',
            children: [
                {
                    value: 'administration',
                    label: 'Administration',
                    children: [
                        {
                            value: 'organization',
                            label: 'Organization',
                            children: [
                                { value: 'organizationList', label: 'List' },
                                { value: 'organizationCreate', label: 'Create' },
                                { value: 'organizationEdit', label: 'Edit' },
                                { value: 'organizationDelete', label: 'Delete' },
                                { value: 'organizationView', label: 'View' },
                                { value: 'mailTemplatePreview', label: 'Mail Template Preview' },
                                { value: 'organizationPermissionView', label: 'Permission View' },
                                { value: 'organizationPermissionEdit', label: 'Permission Edit' }
                            ]
                        },
                        {
                            value: 'role',
                            label: 'Role',
                            children: [
                                { value: 'roleList', label: 'List' },
                                { value: 'roleCreate', label: 'Create' },
                                { value: 'roleEdit', label: 'Edit' },
                                { value: 'roleDelete', label: 'Delete' },
                                { value: 'roleView', label: 'View' },
                                { value: 'rolePermissionView', label: 'Permission View' },
                                { value: 'rolePermissionEdit', label: 'Permission Edit' }
                            ]
                        },
                        {
                            value: 'user',
                            label: 'User',
                            children: [
                                { value: 'userList', label: 'List' },
                                { value: 'userCreate', label: 'Create' },
                                { value: 'userEdit', label: 'Edit' },
                                { value: 'userDelete', label: 'Delete' },
                                { value: 'userView', label: 'View' },
                                { value: 'userInvite', label: 'Invite' },
                            ]
                        },
                        {
                            value: 'blueprint',
                            label: 'Blueprint',
                            children: [
                                { value: 'blueprintList', label: 'List' },
                                { value: 'blueprintCreate', label: 'Create' },
                                { value: 'blueprintEdit', label: 'Edit' },
                                { value: 'blueprintDelete', label: 'Delete' },
                                { value: 'blueprintView', label: 'View' },
                                {
                                    value: 'image',
                                    label: 'Image',
                                    children: [
                                        { value: 'imageList', label: 'List' },
                                        { value: 'imageCreate', label: 'Create' },
                                        { value: 'imageEdit', label: 'Edit' },
                                        { value: 'imageDelete', label: 'Delete' },
                                        { value: 'imageView', label: 'View' }
                                    ]
                                },
                                {
                                    value: 'extension',
                                    label: 'Extension',
                                    children: [
                                        { value: 'extensionList', label: 'List' },
                                        { value: 'extensionCreate', label: 'Create' },
                                        { value: 'extensionEdit', label: 'Edit' },
                                        { value: 'extensionDelete', label: 'Delete' },
                                        { value: 'extensionView', label: 'View' }
                                    ]
                                }
                            ]
                        },
                        
                        {
                            value: 'subscription',
                            label: 'Subscription',
                            children: [
                                { value: 'subscriptionList', label: 'Subscription List' },
                                {
                                    value: 'resourceGroup',
                                    label: 'Resource Group',
                                    children: [
                                        { value: 'resourceGroupList', label: 'List' },
                                        { value: 'resourceGroupCreate', label: 'Create' },
                                        { value: 'resourceGroupEdit', label: 'Edit' },
                                        { value: 'resourceGroupDelete', label: 'Delete' },
                                        { value: 'resourceGroupView', label: 'View' },
                                        { value: 'accessControl', label: 'Access Control' }
                                    ]
                                },
                                {value: 'insightsService', label: 'Insights'},
                                {value: 'metrics', label: 'Metrics'}
                            ]
                        },
                        {
                            value: 'policy',
                            label: 'Policy',
                            children: [
                                { value: 'policyAssign', label: 'Policy Assignment' },
                                {value: 'complianceResource', label: 'Compliance Resource'}, 
                                {value: 'policyDefinition', label: 'Policy Definition'},
                                {value: 'policyReport', label: 'Policy Report'},
                            ]
                        },
                        {
                            value: 'optimizer',
                            label: 'Optimizer',
                            children: [
                                { value: 'costRecommendation', label: 'Cost' },
                                {value: 'securityRecommendation', label: 'Security'},
                                {value: 'highAvailabilityRecommendation', label: 'High Availability'},
                                {value: 'operationalExcellenceRecommendation', label: 'Operational Excellence'},
                                {value: 'performanceRecommendation', label: 'Performance'},
                            ]
                        }
                    ]
                },
                {
                    value: 'marketplace',
                    label: 'Marketplace',
                },
                {
                    value: 'blueprints',
                    label: 'Blueprints',
                },
                {
                    value: 'learn',
                    label: 'Learn',
                    children: [
                        { value: 'learnCreate', label: 'Create' },
                        { value: 'learnList', label: 'List' },
                        { value: 'learnEdit', label: 'Edit' },
                        { value: 'learnView', label: 'View' },
                        { value: 'learnDelete', label: 'Delete' }
                    ]
                },
                
                {
                    value: 'templateConverter',
                    label: 'Converter',
                    children: [
                        { value: 'converter', label: 'Converter' },
                        { value: 'convertedListCreate', label: 'Create' },
                        { value: 'convertedList', label: 'List' },
                        { value: 'convertedListEdit', label: 'Update' },
                        { value: 'convertedListDelete', label: 'Delete' }
                    ]
                },
                {
                    value: 'deployment',
                    label: 'Deployment',
                    children: [
                        { value: 'blueprintProvision', label: 'Blueprint Provision' },
                        {
                            value: 'deploymentActions',
                            label: 'Deployment Actions',
                            children: [
                                { value: 'deploymentList', label: 'Deployment List' },
                                { value: 'resourceManagement', label: 'Resource Management' },
                                { value: 'addDeployment', label: 'Assign' },
                                { value: 'downloadDeployment', label: 'Download' },
                                { value: 'deleteDeployment', label: 'Delete' },
                                { value: 'shareDeployment', label: 'Share' }
                            ]
                        },
                    ]
                },
                {
                    value : "contentmanagementsystem",
                    label : "Content Management System",
                    children : [{
                        value : "contentmanagementsystem", label : "Content Management System"
                    }]
                },
                {
                    value: 'costManagement',
                    label: 'Cost Management',
                    children: [
                        { value: 'costUsageLimit', label: 'Cost Usage Limit' },
                        { value: 'costAnalysis', label: 'Cost Analysis' },
                    ]
                },
                {
                    value: 'resource',
                    label: 'Resource',
                    children: [
                        {
                            value: 'vmList',
                            label: 'VmList',
                            children: [
                                { value: 'portalVmList', label: 'Portal VM List' },
                                { value: 'azureVmList', label: 'Azure VM List' },
                                { value: 'vmInsights', label: 'VM Insights' },
                            ]
                        },
                        {
                            value: 'powerBiDashboard',
                            label: 'Power BI Dashboard',
                            children: [
                                { value: 'powerBiDashboardList', label: 'List' },
                                { value: 'powerBiDashboardCreate', label: 'Create' },
                                { value: 'powerBiDashboardEdit', label: 'Edit' },
                                { value: 'powerBiDashboardView', label: 'View' },
                                { value: 'powerBiDashboardDelete', label: 'Delete' },
                            ]
                        },
                        {
                            value: 'exploreData', label: 'Explore Data',
                            children: [
                                { value: 'exploreDataList', label: 'List' },
                                { value: 'exploreDataCreate', label: 'Create' },
                                { value: 'exploreDataEdit', label: 'Edit' },
                                { value: 'exploreDataShare', label: 'Share' },
                                { value: 'exploreDataView', label: 'View' },
                                { value: 'exploreDataDelete', label: 'Delete' },
                                { value: 'exploreDataQueryEditor', label: 'Query Editor' },
                                { value: 'exploreDataQueryFilter', label: 'Query Filter' },
                                {
                                    value: 'exploreDataDownload', label: 'Data Download',
                                    children: [
                                        { value: 'exploreDataDownloadCsv', label: 'CSV' },
                                        { value: 'exploreDataDownloadJson', label: 'JSON' },
                                    ]
                                },
                            ]
                        },
                        {
                            value: 'storageAccount',
                            label: 'Storage Account',
                            children: [
                                { value: 'storageAccountList', label: 'List' },
                                { value: 'storageAccountCreate', label: 'Create' },
                                { value: 'storageAccountView', label: 'View' },
                                { value: 'storageAccountUpload', label: 'Upload' },
                                { value: 'storageAccountDelete', label: 'Delete' },
                                { value: 'storageAccountInsights', label: 'Insights' },
                                { value: 'storageAccountTable', label: 'Table' },
                            ]
                        },
                        {
                            value: 's3Bucket',
                            label: 'S3 Bucket',
                            children: [
                                { value: 's3BucketList', label: 'List' },
                                { value: 's3BucketCreateFolder', label: 'Create Folder' },
                                { value: 's3BucketUpload', label: 'Upload' },
                                { value: 's3BucketDelete', label: 'Delete' },
                            ]
                        },
                        {
                            value: 'dataLake',
                            label: 'Data Lake',
                            children: [
                                { value: 'dataLakeCreateFolder', label: 'Create Folder' },
                                { value: 'dataLakeUpload', label: 'Upload' },
                                { value: 'dataLakeExplore', label: 'Explore' },
                                { value: 'dataLakeAccess', label: 'Access' },
                                { value: 'dataLakeDelete', label: 'Delete' },
                            ]
                        },
                        {
                            value: 'functionApp',
                            label: 'Function App',
                            children: [
                                { value: 'functionAppList', label: 'List' },
                                {
                                    value: 'appSettings',
                                    label: 'App Settings',
                                    children: [
                                        { value: 'appSettingsAdd', label: 'Add' },
                                        { value: 'appSettingsEdit', label: 'Edit' },
                                        { value: 'appSettingsList', label: 'List' },
                                        { value: 'appSettingsDelete', label: 'Delete' },
                                    ]
                                },
                                { value: 'deploymentCenter', label: 'Deployment Center' },
                            ]
                        },
                        {
                            value: 'form',
                            label: 'Form',
                            children: [
                                { value: 'formList', label: 'List' },
                                { value: 'formCreate', label: 'Create' },
                                { value: 'formEdit', label: 'Edit' },
                                { value: 'formShare', label: 'Share' },
                                { value: 'formRun', label: 'Run' },
                                { value: 'formDelete', label: 'Delete' },
                            ]
                        },
                    ]
                },
                {
                    value: 'websiteSettings',
                    label: 'Website Settings',
                },
            ]
        }
    ];


    TempModule[0]["children"].map((data, index) => {
        if (data.children) {

            data.children.map((DC, j) => {

                if (DC.children) {
                    DC.children.map((DCC, k) => {
                        if (DCC.children) {
                            DCC.children.map((DCCC, L) => {
                                if (permission.indexOf(DCCC.value) === -1) {
                                    TempModule[0]["children"][index]["children"][j]["children"][k]["children"][L] = { value: DCCC.value, label: DCCC.label, disabled: true }
                                }
                                else {
                                    TempModule[0]["children"][index]["children"][j]["children"][k]["children"][L] = { value: DCCC.value, label: DCCC.label, disabled: false }
                                }
                                return '';
                            })
                        } else {
                            if (permission.indexOf(DCC.value) === -1) {
                                TempModule[0]["children"][index]["children"][j]["children"][k] = { value: DCC.value, label: DCC.label, disabled: true }
                            }
                            else {
                                TempModule[0]["children"][index]["children"][j]["children"][k] = { value: DCC.value, label: DCC.label, disabled: false }
                            }
                        }
                        return '';
                    })
                }
                else {
                    if (permission.indexOf(DC.value) === -1) {
                        TempModule[0]["children"][index]["children"][j] = { value: DC.value, label: DC.label, disabled: true }
                    }
                    else {
                        TempModule[0]["children"][index]["children"][j] = { value: DC.value, label: DC.label, disabled: false }
                    }
                }
                return '';
            })
        }
        else {
            if (permission.indexOf(data.value) === -1) {
                TempModule[0]["children"][index] = { value: data.value, label: data.label, disabled: true }
            }
            else {
                TempModule[0]["children"][index] = { value: data.value, label: data.label, disabled: false }
            }
        }

        return '';
    })

    TempModule[0]["children"].map((data, index) => {
        if (data.children) {

        }
        else {
            if (permission.indexOf(data.value) === -1) {
                if (data)
                    if (data["disabled"] === true) {
                        TempModule[0]["children"] = TempModule[0]["children"]?.filter(item => item.value !== data.value);

                    }
            }
        }
        return '';
    })

    TempModule[0]["children"].map((data, index) => {
        if (data.children) {

            data.children.map((DC, j) => {

                if (DC.children) {
                }
                else {
                    if (DC)
                        if (DC["disabled"] === true) {
                            TempModule[0]["children"][index]["children"] = TempModule[0]["children"]?.[index]?.["children"]?.filter(item => item.value !== DC.value);
                        }
                }
                return '';
            })
            return '';
        }
        return '';
    });


    TempModule[0]["children"].map((data, index) => {
        if (data.children) {

            data.children.map((DC, j) => {

                if (DC.children) {
                    DC.children.map((DCC, k) => {
                        if (DCC.children) {
                        } else {
                            if (DCC) {
                                if (DCC["disabled"] === true) {
                                    TempModule[0]["children"][index]["children"][j]["children"] = TempModule[0]["children"][index]["children"][j]["children"].filter(item => item.value !== DCC.value);

                                }
                            }
                        }
                        return '';
                    })
                }
                return '';
            })
        }
        return '';
    })

    TempModule[0]["children"].map((data, index) => {
        if (data.children) {
            data.children.map((DC, j) => {

                if (DC.children) {
                    DC.children.map((DCC, k) => {
                        if (DCC.children) {
                            DCC.children.map((DCCC, L) => {
                                if (DCCC && TempModule[0]["children"][index]?.["children"]?.[j]?.["children"]?.[k]?.["children"]) {

                                    if (DCCC["disabled"] === true) {
                                        TempModule[0]["children"][index]["children"][j]["children"][k]["children"] = TempModule[0]["children"][index]["children"][j]["children"][k]["children"].filter(item => item.value !== DCCC.value);
                                    }
                                }
                                return '';
                            })
                        }
                        return '';
                    })
                }
                return '';
            })
        }
        return '';
    })
    return TempModule;
}

export const PermissionCheck = (moduleKey = "", disptach = "") => {
    let defaultPermission = ["help"];
    if (defaultPermission.indexOf(moduleKey) > -1)
        return true;
    if (moduleKey === "contentmanagementsystem") {
            return true
    }
    let check = store.getState();
    if (check.auth.state === AuthState.LOGGED_IN) {
        let permission =  check?.auth?.account?.roleInfo?.permission;
        let orgPermission = check?.auth?.account?.orgInfo?.permission;
        if ((permission === undefined || orgPermission === undefined) && disptach !== "") {
            return disptach(AuthActions.logout());
        }
        if (orgPermission?.indexOf(moduleKey) === -1) {
            return false;
        }
        else return orgPermission?.indexOf(moduleKey) > -1 && permission.indexOf(moduleKey) > -1 ;
    }
}

export const CheckPermission = (modules = []) => {
    let key;
    for (key in modules) {
        let mod = modules[key];
        if (mod.children)
            if (mod.children.length) {
                mod.children = CheckPermission(mod.children);
                if (mod.children.length === 0)
                    modules = removeByAttr(modules, "value", mod.value);
            }
            else
                modules = removeByAttr(modules, "value", mod.value);
    }
    return modules;
}
const removeByAttr = function (arr, attr, value) {
    let i = arr.length;
    while (i--) {
        if (arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value)) {
            arr.splice(i, 1);
        }
    }
    return arr;
}


export const DisablePermission = (modules = []) => {
    let key;
    for (key in modules) {
        let mod = modules[key];
        if (mod.children) {
            mod['disabled'] = true;
            mod.children = DisablePermission(mod.children);
        }
    }
    return modules;
}


export const CurrentUserPermissions = () => {
    return CheckPermission(UserPermissionModules());
}

export const CurrentAccount = () => {
    let check = store.getState();
    return check.auth.account;
}


export const CurrentState = () => {
    let check = store.getState();
    return check.auth.state;
}

export const Regions = {
    centralindia: "Central India",
    northeurope: "North Europe",
    westeurope: "West Europe",
    uksouth: "UK South",
    ukwest: "UK West",
    eastus: "East US",
    eastus2: "East US 2",
    westus: "West US",
    westus2: "West US 2",
    centralus: "Central US",
    northcentralus: "North Central US",
    southcentralus: "South Central US",
    usgovvirginia: "(US) USGov Virginia",
    usgovarizona: "(US) USGov Arizona",
    usgoviowa: "(US) USGov Iowa",
    usgovtexas: "(US) USGov Texas",
    usdodcentral: "(US) USDoD Central",
    usdodeast: "(US) USDoD East",
};

export const GetRegionName = (key = "") => {
    return Regions[key] ?? key;
};

export const AppDetails = {
    "appId": "dcff5e51-801c-4db5-9d91-e6056b456833", 
    "clientSecret": "9eC7Q~vELkspOgHIZ6ARvAYS58hlWZNnoV0Ac"
    };

export function getRandomInt(max = 255) {
    return Math.floor(Math.random() * max);
}

export const InsightsResource = {
    Storage: 0,
    VirtualMachine: 1
}

export const TimePeriod = {
    Today: 0,
    Yesterday: 1,
    Last7Days: 2,
    LastMonth: 3,
    Last3Months: 4,
    LastYear: 5
}

export const FETCH_STATUS = {
    IDEL: "idel",
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error"
}

export const GetKeysInStrings = (jsonString, pattern = /\{\{(.*?)\}\}/g) => {
    const matches = [...jsonString.matchAll(pattern)];

    return matches.map(match => match[1]).reduce(function (acc, curr) {
        if (!acc.includes(curr))
            acc.push(curr);
        return acc;
    }, []);
}

export function generatePassword(length = 12) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_+=<>?";

    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    // Ensure the password meets Azure VM requirements (modify as needed)
    // Example requirements: At least one uppercase letter, one lowercase letter, one digit, and one special character
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()-_+=<>?]/.test(password);

    if (!(hasUppercase && hasLowercase && hasDigit && hasSpecialChar)) {
        // Regenerate the password if requirements are not met (you can implement a different logic)
        return generatePassword(length);
    }

    return password;
}
    