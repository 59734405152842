
export const ActiveState = {
    UPDATE:"UPDATE"
};

 const data = {
     activeTab: "",
    activeURL:""
};

// Auth Reducer
export default function activeTabReducer (state = data, action) {
    switch (action.type) {
        case ActiveState.UPDATE:
            return {
                activeTab: action.data.tabName,
                activeURL: action.data.url
            };
        default:
            return state;
    }
};

