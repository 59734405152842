export const AuthState = {
    LOGGED_IN: "LOGGED_IN",
    NOT_LOGGED_IN: "NOT_LOGGED_IN",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    SELECT_ACCOUNT_REQUEST: "SELECT_ACCOUNT_REQUEST",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGIN_ERROR: "LOGIN_ERROR",
    SET_TOKEN: "SET_TOKEN",
    ACCOUNT_EXPIRED: "ACCOUNT_EXPIRED",
    UPDATE_PERMISSION: "UPDATE_PERMISSION",
    UPDATE_TENANTID: "UPDATE_TENANTID",
    SWITCH_DIRECTORY: "SWITCH_DIRECTORY",
    SET_PROVIDER: "SET_PROVIDER"
};

export const UserData = {
    token: "",
    azureToken: "",
    secondaryAzureToken:"",
    state: AuthState.NOT_LOGGED_IN,
    tokenExpried: false,
    latestURLPath: "",
    lastLoginTime: "",
    tenantId: "",
    account: {
        name: "",
        email: "",
        state: "",
        orgId: "",
        role: "",
        permission: [],
        orgPermission: []
    },
    provider: "",
    "aws-auth" : "",
    errorMsg: ""
};

// Auth Reducer
const authReducer = (state = UserData, action) => {
    switch (action.type) {
        case AuthState.LOGGED_IN:
            return {
                ...state,
                state: action.type,
                token: action.payload.accessToken,
                azureToken: action.payload.azureToken,
                account: action.account,
                tokenExpried: false,
                tenantId: action.payload.tenantId,
                latestURLPath: action.lastUrl,
                lastLoginTime: new Date()
            };
        case AuthState.SWITCH_DIRECTORY:
            return {
                ...state,
                token: "",
                azureToken: "",
                state: AuthState.SWITCH_DIRECTORY,
                tokenExpried: false,
                latestURLPath: action.lastPath,
                lastLoginTime: "",
                account: {
                    name: "",
                    email: "",
                    status: "",
                    orgId: "",
                    role: "",
                    orgInfo: {},
                    roleInfo: {}
                },
                errorMsg: ""
            }
        case AuthState.UPDATE_PERMISSION:
            let org = state.account.orgInfo;
            org.permission = action.key === "org" ? action.permission : state.account.orgInfo.permission;
            let role = state.account.roleInfo;
            role.permission = action.key === "user" ? action.permission : state.account.roleInfo.permission;
            return {
                ...state,
                account: {
                    name: state.account.name,
                    email: state.account.email,
                    status: state.account.status,
                    orgId: state.account.orgId,
                    role: state.account.role,
                    orgInfo: org,
                    roleInfo: role
                },
                errorMsg: state.errorMsg
            }
        case AuthState.UPDATE_TENANTID:
            return {
                ...state,
                tenantId: action.id
            }
        case AuthState.LOGIN_REQUEST:
            return {
                state: action.type
            }
        case AuthState.SET_TOKEN:
            return {
                state: action.type,
                token: action.payload.accessToken,
                azureToken: action.payload.azureToken,
            };
        case AuthState.LOGIN_ERROR:
            return {
                state: action.type,
                errorMsg: action.errorMsg
            };
        case AuthState.TOKEN_EXPIRED:
            return {
                ...state,
                state: action.type,
                tokenExpried: true,
                latestURLPath: action.urlPath,
                errorMsg: "Error validating access token: Your token has expired!"
            };
        case AuthState.SET_PROVIDER:
            return {
                ...state,
                provider: action.provider,
                "aws-auth" : action["aws-auth"]
            }
        case AuthState.NOT_LOGGED_IN:
            return {
                ...state,
                token: "",
                azureToken: "",
                state: AuthState.NOT_LOGGED_IN,
                tokenExpried: false,
                latestURLPath: action.lastPath,
                lastLoginTime: "",
                tenantId:"",
                account: {
                    name: "",
                    email: "",
                    status: "",
                    orgId: "",
                    role: "",
                    orgInfo: {},
                    roleInfo: {}
                },
                errorMsg: ""
            }
        default:
            return state;
    }
};

export default authReducer;