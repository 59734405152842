import React, { useEffect, Suspense, lazy } from "react";
import { withWidth } from "@material-ui/core";
import { GetChannelCode } from "../../const";
import { Spinner, SpinnerSize } from "office-ui-fabric-react"

function Index() {
    const [ChannelWidget, setChannelWidget] = React.useState(lazy(() => import(`./initialWidget`)));

    useEffect(() => {
        GetChannelCode().then(res => {
            try {
              setChannelWidget(lazy(() => import(`./Channel/${res.data.data}`)));
            } catch {
                setChannelWidget(lazy(() => import(`./Channel/MANAGEX`)));
            }
        })
    }, []);

    return (
        <React.Fragment>
            <Suspense fallback={
                <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Loading...."
                    ariaLive="assertive" labelPosition="right" />} >
                <ChannelWidget />
            </Suspense>
        </React.Fragment>
    );

}

export default withWidth()(Index);

