import React, { Component } from "react"
import { Grid, Paper } from "@material-ui/core"
import AccessDenied from "../assert/images/AccessDenied.svg"
import DarkTileBackground from "../assert/images/darkTileBackground.webp"
import Logo from "../assert/images/ManageX-logo_new.webp"
import { Text } from "office-ui-fabric-react"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    }
  }

  render() {
    if (this.state.hasError)
      return (
        <Paper
          elevation={3}
          style={{
            backgroundImage: `url(${DarkTileBackground})`,
            backgroundPosition: "center"
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ paddingTop: 20 }}
          >
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <img src={Logo} alt="Logo" style={{ width: 150 }} />
              <div style={{ marginTop: 30, marginBottom: 20 }}>
                <Text style={{ color: "#14E2C5", fontSize: "20px" }}>
                  Something went wrong
                </Text>
              </div>

              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src={AccessDenied}
                alt="Fetching blueprint"
                style={{
                  width: "70%",
                  height: "70%",
                  float: "right",
                  margin: "10px 50px 25px 10px"
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )

    return this.props.children
  }
}

export default ErrorBoundary
