import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from "redux-persist/lib/storage";

import authReducer from "./Auth/reducer";
import activeTabReducer from "./ActiveTab/reducer";
import appSettingsReducer from "./AppSettings/reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'appSettings']
};

const rootReducer = combineReducers({
    auth: authReducer,
    active: activeTabReducer,
    appSettings: appSettingsReducer
})

export default persistReducer(persistConfig, rootReducer)