import React, {Component} from "react";
import {BrowserRouter, Switch} from "react-router-dom";
import PrivateRoute from "./router/privateRoute";
import PublicRoute from "./router/publicRoute";
import history from "./history";
import EduResearchPortal from './components/eduResearchPortal';
import LandingPage from './components/eduResearchLandingPage';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./modules/redux/store";

class EduResearch extends Component {
    render() {
        return (
            <div>
                <Provider store={store}>
                    <BrowserRouter>
                        <PersistGate persistor={persistor}>
                            <Switch history={history}>
                                <PrivateRoute path='/portal' component={EduResearchPortal} componentName={"marketplace"}/>
                                <PublicRoute path='/' component={LandingPage} />
                                <PublicRoute path='/hpc/i' component={LandingPage} />
                                <PublicRoute path='/research' component={LandingPage} />
                            </Switch>
                        </PersistGate>
                    </BrowserRouter>
                </Provider>
            </div>
        );
    }
}

export default EduResearch;
