import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Badge,
  Button,
  IconButton,
  Typography,
  Radio,
  TextField
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import LandingPageBg from "../assert/images/banner-bg.png";
import research from "../assert/images/research.webp";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DrawerBackgroundImage from "../assert/images/DrawerBackgroundImage.png";
import LandingPageBackgroundImage from "../assert/images/landingPage/landingpage_bg.webp";
import { Dropdown } from "office-ui-fabric-react";

import Content2 from "../assert/images/landingPage/content2.png";
import ContentSmall2 from "../assert/images/landingPage/ContentSmall2.png";
import Cloud from "../assert/images/landingPage/capabilities/cloud.png";


const drawerWidth = 240;

const styles = (theme) => ({
  menuButton: {
    marginRight: 0,
    color: "white",
    fontFamily: "Segoe UI"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "#273041",
    backgroundImage: `url(${DrawerBackgroundImage})`,
    backgroundSize: "cover",
    color: "white"
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
    backgroundColor: "#273041",
    backgroundImage: `url(${DrawerBackgroundImage})`,
    backgroundSize: "cover",
    color: "white"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 3, 0, 0)
  },

  font: {
    backgroundColor: "red"
    // fontFamily: "poppins"
  },

  backgroundVector: {
    height: "85%"
    // position: 'absolute',
  },
  root: {
    flexGrow: 1,
    display: "flex"
    // backgroundColor: theme.palette.background.paper,
  },
  /*  drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },*/
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
    color: "#000"
  },
  appBarLogoPlaceholder: {
    background: "#ffffff",
    color: "#fff",
    position: "absolute",
    left: "0px",
    width: drawerWidth,
    height: "100%"
  },
  appBarLogo: {
    height: "100%",
    textAlign: "center",
    paddingTop: "15px"
  },
  appBarMenu: {
    marginLeft: "auto"
  },
  appBarProfileIcon: {
    fontSize: "35px"
  },
  appBarNotificationIcon: {
    fontSize: "35px"
  },
  appBarProfileName: {
    paddingLeft: "5px",
    fontWeight: "500"
  },
  menuIcon: {
    marginLeft: theme.spacing(2)
  },
  menuName: {
    marginLeft: theme.spacing(1)
  },
  menu: {
    margin: "5px 7px 0px 7px",
    padding: "12px 0 12px 0",
    color: "#fff"
  },
  /*  menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },*/
  navBar: {
    backgroundColor: "#273041",
    height: "100%"
  },
  /* toolbar: theme.mixins.toolbar,*/
  drawerPaper: {
    width: drawerWidth
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  /* content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },*/
  logo: {
    marginRight: "auto",
    marginLeft: 0,
    width: 250,
    height: 55
  },
  Headline: {
    marginTop: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(3),
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  layout: {
    width: "auto",
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  poolSectionPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(5)
    }
  },
  heading: {
    fontSize: "35px",
    fontWeight: "bold",
    marginBottom: "10px",
    [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
      marginLeft: "0px",
      textAlign: "center"
    }
  },
  headingAppBarSpace: {
    marginTop: "20px"
  },
  headingLogo: {
    width: "170px",
    // marginLeft: '50px',
    [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
      marginLeft: "20px",
      textAlign: "center"
    }
  },
  layoutLogo: {
    width: "120px",
    marginTop: 15,
    //  paddingLeft: '25px',
    paddingTop: "0px"
  },
  layoutPreview: {
    // textAlign: 'center',
    fontSize: "10px",
    float: "right",
    color: "black",
    position: "absolute",
    left: "200px",
    top: "42px"
  },

  //LandingPage CSS
  landingPageLogo: {
    fontSize: "25px",
    fontWeight: "bold",
    margin: "10px 10px 10px 20%"
  },
  landingPageSignInButton: {
    float: "right",
    margin: "10px 20% 10px 100px",

    [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
      float: "left",
      margin: "30px 0 0px 150px"
    }
  },
  landingPageContent: {
    marginTop: "15px",
    backgroundImage: `url(${LandingPageBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  landingPageHeadline: {
    width: "100%",
    textAlign: "left",
    fontSize: "30px",
    fontWeight: "bold",
    lineHeight: "110%",
    color: "#0A64DC",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px"
    }
  },
  landingPageHeadline2: {
    color: "#1A73E7",
    textAlign: "left",
    padding: "20px"
  },
  greenbgsection: {
    background: "#06818B",
    padding: "100px 100px",
    fontSize: "30px",
    textAlign: "center",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 30px",
      fontSize: "12x"
    }
  },

  maxwidth: {
    width: "100%"
  },
  benefictssection: {
    padding: "20px",
    background: "rgba(0, 0, 0, 0.6);",
    float: "left",
    marginTop: "20px"
  },
  landingPageFeatureContentHeadingtop: {
    paddingBottom: "20px",
    fontSize: "18px"
  },
  beneficts: {
    backgroundImage: `url(${research})`,
    backgroundSize: "cover",
    padding: "100px 0  100px 0",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0  20px 0"
    }
  },
  benefictssectionContainer: {
    padding: "10px 0 0 0",
    float: "left",
    color: "#fff"
  },
  benefictssectionContainerSubheding: {
    padding: "10px 0 1px 20px",
    float: "left",
    fontSize: "22px"
  },

  benefictssectionContainersecondsection: {
    marginTop: "30px",
    float: "left"
  },

  benefictheding: {
    padding: "5px 0 0 20px"
  },

  benefictssectionDescription: {
    padding: "5px 0 0 20px",
    fontSize: "20px"
  },
  consectionShape: {
    textAlign: "center",
    fontSize: "25px",
    padding: "100px 0 80px 0",
    background: "#F5F2FE",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      padding: "30px 0 30px 0"
    }
  },

  footerContent: {
    padding: "15px",
    textAlign: "center",
    color: "white"
  },

  landingPagecontainer: {
    background: "rgba(0, 0, 0, 0.6);",
    paddingBottom: "70px",
    marginBottom: "200px",
    marginTop: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  BlueprintParagraph: {
    marginBottom: "30%",
    paddingBottom: "10%",
    color: "#000",
    padding: "20px"
  },

  SolutionHeadLine: {
    fontSize: 14,
    fontWeight: 500
  },

  SolutionSubTitle: {
    fontSize: 12,
    color: "grey"
  },

  landingPageHeadParagraph: {
    paddingTop: 10,
    fontSize: "12px",
    color: "#fff"
  },

  landingPageLandingImageHolder: {
    width: "100%",
    textAlign: "center"
  },

  landingPageLandingImage: {
    width: "75%"
  },

  landingPageGreyDotHolder: {
    position: "absolute",
    zIndex: 3,
    right: "205px",
    marginTop: "-110px"
  },

  landingPageGreyDot: {
    width: "10px",
    marginRight: "20px",
    marginTop: "20px"
  },

  landingPageFeaturesCard: {
    backgroundColor: "#fff",
    position: "relative",
    zIndex: 2,
    width: "98%",
    border: "1px solid gray",
    borderRadius: "0px 20px 20px 0px",
    borderLeft: "1px solid #fff",
    marginTop: "-35px"
  },

  landingPageBackground: {
    backgroundColor: "#e6e3ef",
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "550px",
    marginTop: "125px"
  },

  landingPageFeatures: {
    margin: "6% 14% 7% 5%"
  },
  landingPageFeaturesCardName: {
    fontSize: "17px",
    fontWeight: "500"
  },
  landingPageFeaturesTitleContainer: {
    marginTop: "20px",
    marginBottom: "60px"
  },
  landingPageFeaturesTitle: {
    display: "inline-block",
    verticalAlign: "middle",
    fontSize: "40px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  landingPageFeatureContentIcon: {
    width: "80px",
    [theme.breakpoints.down("sm")]: {
      width: "40px"
    }
  },
  landingPageFeatureYellowCircle: {
    width: "73px",
    height: "73px",
    borderRadius: "100px",
    textAlign: "center",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  landingPageFeatureOrangeCircle: {
    width: "73px",
    height: "73px",
    borderRadius: "100px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      float: "left"
    }
  },
  landingPageFeatureSkyBlueCircle: {
    width: "73px",
    borderRadius: "100px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      float: "left"
    }
  },
  landingPageFeatureBlueCircle: {
    width: "73px",
    height: "73px",
    borderRadius: "100px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      float: "left"
    }
  },
  IconTop: {
    width: "100%",
    float: "left"
  },
  topiconitem: {
    float: "left"
  },
  mainPagePreview: {
    width: "250px",
    marginLeft: "165px",
    textAlign: "center"
  },
  optional: {
    fontSize: "10px"
  },

  featureicon: {
    width: "40px",
    paddingTop: "10px"
  },

  landingPageFeatureContentList: {
    marginBottom: "40px"
  },
  landingPageFeatureContentContainer: {
    display: "table-cell",
    verticalAlign: "middle"
  },
  landingPageFeatureContentHeading: {
    marginLeft: "30px",
    fontSize: "25px",
    fontWeight: "700"
  },
  landingPageFeatureContentParagraph: {
    fontWeight: "normal",
    marginTop: "10px",
    fontSize: "20px"
  },
  landingPageBackground2: {
    backgroundColor: "#1CB0BE",
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "400px",
    marginTop: "550px"
  },
  landingPageSectionCardContent: {
    // display: 'inline-block',
    // verticalAlign: 'middle',
    margin: "11% 20% 0% 20%",
    zIndex: 3,
    color: "#fff",
    textAlign: "center",
    fontSize: "25px",
    fontWeight: "500",
    lineHeight: "175%"
  },

  //Blueprint CSS
  submitBlueprint: {
    float: "right",
    fontSize: "17px",
    fontWeight: "500",
    marginBottom: "10px",
    marginRight: "25px",
    cursor: "pointer"
  },
  submitBlueprintFormFields: {
    marginBottom: theme.spacing(2)
  },
  blueprintMenuHolder: {
    width: "90%",
    position: "relative"
  },
  blueprintMenuPaper: {
    // margin: '15px 40px 0px 5px',
    margin: "15px 65px 0px 5px",
    minHeight: theme.spacing(15),
    cursor: "pointer"
  },
  blueprintMenuPaperSelected: {
    // margin: '15px 40px 0px 5px',
    margin: "15px 65px 0px 5px",
    minHeight: theme.spacing(15),
    backgroundColor: "#1e9ff2 !important",
    color: "#fff !important",
    cursor: "pointer"
  },
  blueprintDetailsPaper: {
    // margin: '15px 40px 0px 5px',
    margin: "15px 25px 30px 30px"
  },
  blueprintMenuHeading: {
    fontSize: "15px",
    fontWeight: "500",
    margin: "15px 15px 10px 0px"
  },
  blueprintMenuParagraph: {
    fontSize: "12px",
    marginBottom: "20px"
  },

  blueprintIconBox: {
    width: "50px",
    height: "50px",
    border: "1px solid gray",
    borderRadius: "10px 0px 10px 0px",
    backgroundColor: "#fff",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle"
  },
  blueprintIconHolder: {
    margin: "20px 0px 0px 10px"
  },
  blueprintIcon: {
    width: "30px"
  },
  blueprintHeadingIconCircle: {
    width: "45px",
    height: "45px",
    backgroundColor: "#e8f2ff",
    borderRadius: "100px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    paddingTop: "10px"
  },
  blueprintHeadingIcon: {
    width: "20px"
  },
  trianglePosition: {
    float: "right",
    margin: "-20px"
  },
  triangleRight: {
    width: 0,
    height: 0,
    borderTop: "8px solid transparent",
    borderLeft: "14px solid #1e9ff2",
    borderBottom: "8px solid transparent"
  },
  blueprintDetailsHeading: {
    fontSize: "25px",
    fontWeight: "bold",
    padding: "20px 15px 20px 15px"
  },
  blueprintDetailsParagraph: {
    fontSize: "14px",
    padding: "10px 15px 20px 25px",
    lineHeight: "200%"
  },
  blueprintDetailsParagraphStyle: {
    marginBottom: "10px"
  },
  blueprintDetailsDeployButton: {
    textAlign: "right",
    padding: "10px 30px 50px 0px"
  },

  // Deploy Page CSS
  deployHeading: {
    fontSize: "30px",
    fontWeight: "bold"
    //marginBottom: '30px',
  },
  deploymentTitle: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "25px 0px 25px 25px"
  },
  subscriptionForm: {
    padding: "10px 25px 25px 25px"
  },
  subscriptionImage: {
    width: "85%",
    float: "right",
    margin: "10px 50px 25px 10px"
  },
  subscriptionButtons: {
    textAlign: "right",
    padding: "20px 0px 20px 0px"
  },
  subscriptionButton: {
    marginLeft: "10px"
  },
  addPoolButton: {
    marginLeft: "10px"
  },
  addPoolButtonAddIcon: {
    marginRight: "10px"
  },
  subscriptionButtonNextIcon: {
    marginLeft: "10px"
  },
  subscriptionButtonBackIcon: {
    marginRight: "10px"
  },
  paramsPaperBackground: {
    backgroundColor: "white",
    display: "inline-block",
    // margin: '10px 10px 10px 10px',
    width: "-webkit-fill-available"
  },
  paramsTitleIconPlaceholder: {
    padding: "25px 0px 25px 25px"
  },
  paramsTitleIcon: {
    width: "50px"
  },
  paramsTitle: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "15px 0px 25px 10px"
  },
  paramsFormField: {
    marginLeft: "16px !important",
    marginRight: "24px !important",
    marginTop: "24px !important",
    marginBottom: "24px !important",
    width: "90%",
    backgroundColor: "#fff"
  },
  paramsFormFieldForAutentication: {
    marginLeft: "16px !important",
    marginRight: "24px !important",
    marginTop: "24px !important",
    marginBottom: "48px !important",
    width: "90%",
    backgroundColor: "#fff"
  },
  paramsFormFieldForCheck: {
    marginLeft: "16px !important",
    marginRight: "24px !important",
    marginTop: "24px !important",
    width: "90%",
    backgroundColor: "#fff",
    borderColor: "red"
  },
  paramsAddResourceGroupHolder: {
    display: "flex",
    alignItems: "center",
    height: "90%",
    marginLeft: "6%"
  },
  paramsAdministratorTitle: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "20px 0px 25px 25px"
  },
  poolSectionTitle: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "20px 0px 25px 25px"
  },
  ImageTypeSelection: {
    padding: "25px 0px 0px 25px"
  },
  paramsAdministratorRadioButtonHolder: {
    padding: "0px 0px 0px 25px"
  },
  paramsAdministratorRadioButtons: {
    padding: "0px 0px 25px 25px"
  },
  summaryHeading: {
    fontSize: "17px",
    fontWeight: "600",
    padding: "0px 0px 10px 25px"
  },
  summaryHeading2: {
    fontSize: "17px",
    fontWeight: "600",
    padding: "0px 0px 50px 25px"
  },
  summaryParagraph: {
    fontSize: "17px",
    fontWeight: "300"
  },
  summaryResourceIcon: {
    width: "45px"
  },
  summaryResourceTitle: {
    fontSize: "25px",
    fontWeight: "600",
    padding: "15px 0px 25px 0px"
  },
  statusTitle: {
    fontSize: "22px",
    fontWeight: "600",
    padding: "25px 0px 25px 25px"
  },
  statusDeployment: {
    fontSize: "30px",
    fontWeight: "700",
    margin: "15px 0px 15px 0px"
  },
  statusParagraph: {
    fontSize: "16px",
    fontWeight: "300",
    marginBottom: "35%",
    paddingLeft: "10%",
    paddingRight: "10%"
  },
  statusLoaderPlaceholder: {
    marginTop: "5%",
    width: "100%",
    textAlign: "-webkit-center"
  },
  statusLoader: {
    width: "10%"
  },
  statusError: {
    fontSize: "80px",
    color: "red"
  },
  statusWarning: {
    fontSize: "80px",
    color: "#ff922c"
  },
  statusSuccess: {
    fontSize: "80px",
    color: "green"
  },
  errorTableContainer: {
    width: "100%"
  },
  errorTable: {
    // marginTop: '20px',
    width: "80%",
    margin: "3% 10% 5% 10%",
    borderCollapse: "collapse",
    border: "1px solid #424e58",
    "& th": {
      border: "1px solid #424e58"
    },
    "& td": {
      border: "1px solid #424e58",
      padding: "10px 10px 10px 10px"
    }
  },
  errorTableHeading: {
    fontWeight: "600"
  },
  fetchingSubscription: {
    margin: "20px",
    textAlign: "center"
  },
  fetchingBlueprint: {
    margin: "20px",
    textAlign: "center"
  },
  subscriptionMessage: {
    // margin: '45px',
    lineHeight: "220%",
    textAlign: "justify",
    textJustify: "inter-word"
  },
  priceLabel: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "40px 0px 25px 25px"
  },
  priceLabelEstimate: {
    fontSize: "10px",
    position: "absolute"
  },
  loadingPrice: {
    padding: "40px 0px 25px 25px"
  },
  deploymentListTable: {
    "&table": {
      width: "100%"
    },
    "&td, th": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
      wordWrap: "break-word"
    },
    "&tr:nth-child(even)": {
      backgroundColor: "#dddddd"
    }
  },
  deploymentListTableLink: {
    textAlign: "center"
  },
  blueprintSearchBarDiv: {
    marginLeft: "200px"
  },
  blueprintMenuTitle: {
    overflow: "auto"
  },
  tagsParamsFormField: {
    marginLeft: "16px",
    marginRight: "24px",
    marginTop: "24px",
    marginBottom: "24px",
    width: "90%",
    backgroundColor: "#fff"
  },
  tableContainer: {
    marginTop: "10px"
  },
  buttonSuccess: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green"
    }
  },
  fabProgress: {
    color: "darkblue",
    position: "absolute",
    left: -3,
    zIndex: 1
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline"
  },
  required: {
    "&:after": {
      content: "",
      color: "rgb(164, 38, 44)",
      paddingRight: "12px"
    }
  },
  TreeViewStyle: {
    fontFamily: "Segoe UI Web (West European)",
    fontSize: "14px"
  },

  /* New ManageX Design - 14 Nov 2022 */

  TransitionScale: {
    cursor: "pointer",
    transition: "all .25s ease-in-out",
    fontSize: 50,
    lineHeight: 1,
    "&:hover": {
      transform: "scale(1.2)",
      color: "#6837FA"
    }
  },
  Content2Bg: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#19273E",
    backgroundImage: `url(${Content2})`,
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    color: "#fff",
    padding: "150px 0 190px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "220px",
      backgroundImage: `url(${ContentSmall2})`
    }
  },
  Content2Heading: {
    paddingBottom: "16px"
  },
  Content3Bg: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#19273E",
    color: "#fff",
    paddingBottom: "90px"
  },
  Content1Bg: {
    backgroundColor: "#19273E",
    color: "#fff",
    textAlign: "center",
    width: "100%",
    marginTop: "-1px"
  },
  Content1GridMob: {
    padding: "90px 0"
  },
  Content1Content: {
    paddingBottom: "16px"
  },
  Content1ContentMob: {
    "& h1": {
      fontSize: "24px"
    }
  },
  Content1CardContentMob: {
    "& h4": {
      fontSize: "12px"
    },
    "& h2": {
      fontSize: "18px"
    }
  },
  Content2CardContent: {},
  Content2CardContentMob: {
    "& h4": {
      fontSize: "12px"
    },
    "& h2": {
      fontSize: "18px"
    }
  },
  Content2CardContentTopMob: {
    padding: "0 !important"
  },
  Content4Bg: {
    width: "100%",
    // height: '120vh',
    // backgroundImage: `url(${Content4})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    backgroundColor: "#283C5F"
    // padding: "110px 0 0"
  },
  capabilitiesList: {
    overflowX: "scroll",
    marginBottom: "24px",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar-track-piece": {
      maxWidth: "200px",
      background: "transparent",
      boxShadow: "inset 0 0 5px #969696",
      borderRadius: "4px",
      borderTop: "1.5px solid transparent",
      borderBottom: "1.5px solid transparent",
      height: "200px"
    },
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px"
    },
    "&::-webkit-scrollbar-track": {
      maxWidth: "80% !important",
      marginLeft: "10vw",
      marginRight: "10vw"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(to right, #0090F9, #5A26F5)",
      borderRadius: "8px"
    }
  },
  Content4CloudBg: {
    width: "100%",
    textAlign: "center",
    height: "100%",
    backgroundImage: `url(${Cloud})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  banner: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${LandingPageBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    backgroundColor: "#283C5F"
  },
  whatsManageX: {
    backgroundColor: "#283c5f",
    color: "#ffffff",
    padding: "90px 0",
    "& h3": {
      fontSize: "1.7rem",
      fontWeight: "600"
    },
    "& span": {
      color: "#13cfb4"
    },
    "& p": {
      fontSize: "1.3rem",
      lineHeight: "1.5"
    }
  },
  cloudImage: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem"
    }
  },
  navbarDesk: {
    width: "85%",
    margin: "auto",
    padding: "2% 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  navbarMob: {
    width: "85%",
    margin: "auto",
    padding: "2% 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  navbarUlLi: {
    listStyle: "none",
    display: "inline-block",
    margin: "0 20px",
    position: "relative",
    cursor: "pointer",
    "&, &:after": {
      content: " ",
      height: "6px",
      width: 0,
      background: "#0080F9",
      position: "absolute",
      left: 0,
      bottom: "8px",
      transition: "0.25s",
      borderRadius: "4px"
    },
    "&, &:hover, &:after": {
      width: "100%"
    }
  },
  navbarUlLiActive: {
    listStyle: "none",
    display: "inline-block",
    margin: "0 20px",
    position: "relative",
    cursor: "pointer",
    width: "100%",
    "&, &:after": {
      content: " ",
      height: "6px",
      width: 0,
      background: "#0080F9",
      position: "absolute",
      left: 0,
      bottom: "8px",
      transition: "0.25s",
      borderRadius: "4px"
    }
  },
  navbarUlLiSpan: {
    textDecoration: "none",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "20px",
    fontFamily: "Segoe UI, Semibold"
  },
  navbar: {
    width: "85%",
    margin: "auto",
    padding: "2% 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& ul li": {
      listStyle: "none",
      display: "inline-block",
      margin: "0 20px",
      position: "relative",
      cursor: "pointer",
      "&:after": {
        content: " ",
        height: "6px",
        width: 0,
        background: "#0080F9",
        position: "absolute",
        left: 0,
        bottom: "8px",
        transition: "0.25s",
        borderRadius: "4px"
      },
      "&:hover": {
        "&:after": {
          width: "100%"
        }
      }
    },
    "& ul li a": {
      textDecoration: "none",
      color: "#fff",
      textTransform: "capitalize",
      fontSize: "20px",
      fontFamily: "Segoe UI, Semibold"
    }
  },
  navbarLogoDesktop: {
    width: "24%",
    cursor: "pointer"
  },
  navbarLogoMobile: {
    width: "42%",
    cursor: "pointer"
  },
  navbarActive: {
    width: "100% !important"
  },
  newLandingPageContent: {
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    color: "#fff",
    "& h1": {
      fontSize: "72px",
      fontFamily: "Segoe UI, bold"
    },
    "& p": {
      fontSize: "24px"
    }
  },
  newLandingPageContentMob: {
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    color: "#fff",
    "& h1": {
      fontSize: "28px",
      fontFamily: "Segoe UI, bold"
    },
    "& p": {
      fontSize: "12px"
    }
  },
  newLandingPageContentButton: {
    backgroundColor: "#6837FA",
    border: "none",
    color: "white",
    padding: "16px 64px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "24px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "8px",
    textTransform: "uppercase"
  },
  newLandingPageContentButtonMob: {
    marginTop: "32px",
    backgroundColor: "#6837FA",
    border: "none",
    color: "white",
    padding: "16px 64px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "8px",
    textTransform: "uppercase"
  },
  formContent: {
    "& input[type=text]": {
      width: "100%",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #C3CCE8",
      borderRadius: "5px",
      boxSizing: "border-box",
      background: "#FCFDFF 0 0 no-repeat padding-box",
      height: "50px"
    },
    "& input::placeholder": {
      opacity: 1,
      color: "#7E92AC"
    },
    "& textarea": {
      width: "100%",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #C3CCE8",
      borderRadius: "4px",
      boxSizing: "border-box",
      background: "#FCFDFF 0 0 no-repeat padding-box",
      resize: "none"
    },
    "& textarea::placeholder": {
      opacity: 1,
      color: "#7E92AC"
    }
  },
  formContentMob: {
    position: "relative",
    top: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& form": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "90%",
      [theme.breakpoints.down("md")]: {
      width: "100%",
      },
    },

    "& h1": {
      fontSize: "32px"
    },
    "& input[type=text]": {
      width: "100%",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #C3CCE8",
      borderRadius: "4px",
      boxSizing: "border-box",
      background: "#FCFDFF 0 0 no-repeat padding-box"
    },
    "& input::placeholder": {
      opacity: 1,
      color: "#7E92AC"
    },
    "& textarea": {
      width: "100%",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #C3CCE8",
      borderRadius: "4px",
      boxSizing: "border-box",
      background: "#FCFDFF 0 0 no-repeat padding-box",
      resize: "none"
    },
    "& textarea::placeholder": {
      opacity: 1,
      color: "#7E92AC"
    },
    "& input[type=submit]": {
      backgroundColor: "#233644",
      color: "white",
      padding: "14px 20px",
      margin: "8px 0",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer"
    }
  },
  footer: {
    backgroundColor: "#19273E",
    width: "100%",
    color: "#fff",
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "auto"
    }
  },
  footerMob: {
    backgroundColor: "#19273E",
    width: "100%",
    color: "#fff",
    fontSize: "12px",
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "auto",
      flexDirection: "column"
    }
  },
  footerLeft: {
    "& p": {
      padding: "10px"
    },
    "& p a": {
      color: "#fff"
    }
  },
  footerRight: {
    "& p a": {
      color: "#fff"
    }
  },
  separator: {
    color: "#a7a8a8"
  },
  mainSection: {
    display: "flex",
    flexDirection: "column"
  }
});

// Custom Override Theme

export const DeployButton = withStyles({
  root: {
    background: "#0A64DC",
    borderRadius: "5px",
    border: 0,
    color: "#fff",
    height: 30,
    padding: "0 10px",
    fontSize: "128px",
    //boxShadow: '0 3px 5px 2px rgba(0,0,0, .2)',
    "&:hover": {
      backgroundColor: "#064496"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      background: "#0C1F35"
    },
    "& label.Mui-focused": {
      color: "white",
      fontSize: 12
    },
    "& label": {
      color: "white",
      fontSize: 12
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8989A2",
      color: "white",
      fontSize: 12
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8989A2",
        color: "White",
        fontSize: 12
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
        fontSize: 12
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
        fontSize: 12
      }
    }
  }
})(TextField);

export const CssTextAreaLandingPageField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      background: "#ffffff"
    },
    "& label.Mui-focused": {
      color: "white",
      fontSize: 12
    },
    "& label": {
      color: "white",
      fontSize: 12
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8989A2",
      color: "white",
      fontSize: 12
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8989A2",
        color: "White",
        fontSize: 12
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
        fontSize: 12
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
        fontSize: 12
      }
    }
  }
})(TextField);

export const NextButton = withStyles({
  root: {
    background: "#1e9ff2",
    borderRadius: "10px",
    border: 0,
    color: "#fff",
    height: 40,
    padding: "0 30px",
    fontSize: "15px",
    boxShadow: "0 3px 5px 2px rgba(0,0,0, .2)",
    "&:hover": {
      backgroundColor: "#424e58"
    },
    "&:disabled": {
      backgroundColor: "#CBCBCB",
      boxShadow: "0 0px 0px 0px",
      color: "000"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

export const BackButton = withStyles({
  root: {
    fontFamily: "Segoe UI",
    background: "#000000",
    borderRadius: "10px",
    border: 0,
    color: "#fff",
    height: 40,
    padding: "0 30px",
    fontSize: "15px",
    boxShadow: "0 3px 5px 2px rgba(0,0,0, .2)",
    "&:hover": {
      backgroundColor: "#424e58"
    }
  },
  label: {
    textTransform: "capitalize"
  }
})(Button);

export const DropdownField = withStyles({
  root: {
    // fontFamily: "poppins, Segoe UI"
  }
})(Dropdown);

export const NotificationBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#c03d1c",
    color: "#fff"
  }
}))(Badge);

export const BlueRadio = withStyles({
  root: {
    color: "#1e9ff2",
    "&$checked": {
      color: "#1e9ff2"
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "600px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

export const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center"
  },
  body: {
    fontSize: 14,
    textAlign: "center"
  }
}))(TableCell);

export const CustomTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
    // "&:hover": {
    //     cursor: "pointer"
    // }
  }
}))(TableRow);

export const HomeStyles = {
  title: {
    fontSize: "30px"
  }
};

export const dashboardStyles = makeStyles((theme) => styles(theme), {
  index: 1
});
export const dashboardStylesCore = (theme) => styles(theme);
