import { ActiveState } from "./reducer";

const updateActiveTab = (tabName, url) => {
    const success = (result) => {
        return {
            type: ActiveState.UPDATE,
            data: result
        }
    }
    return (dispatch) => {
        let data = {
            type: ActiveState.UPDATE,
            tabName: tabName,
            url: url
        }
        dispatch(success(data))
    }
}

export const ActiveTabActions = {
    updateActiveTab
}