import React, {lazy, Suspense, useEffect} from 'react';
import {Link} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { dashboardStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { ActiveTabActions, AuthActions } from "../../modules/redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { PermissionCheck } from "../../const";
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { withWidth } from '@material-ui/core';
import { Text, Spinner, SpinnerSize, Stack } from 'office-ui-fabric-react';
import { useId } from '@fluentui/react-hooks';
import { DirectoryService } from "./actions";
import { OrgService } from "../Organization/actions";
import { setIconOptions } from '@fluentui/react/lib/Styling';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import DrawerActiveBar from "../../assert/images/DrawerActiveBar.png"

let TopBar = lazy(() => import("./TopBar"));
let MainSection = lazy(() => import("./MainSection"));
let DirectoryDialog = lazy(() => import("./DirectoryDialog"));

setIconOptions({
    disableWarnings: true
});
initializeIcons(undefined, { disableWarnings: true });

const useStyles = makeStyles((theme) => ({
    '@global': {
       '*::-webkit-scrollbar': {
            width: '0.4em',
           height:'0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-border-radius': 10,
            'border-radius': 10,
            backgroundColor: 'rgba(240,240,240)',
        },
        '*::-webkit-scrollbar-thumb': {
            '-webkit-border-radius': 10,
            'border-radius': 10,
            backgroundColor: 'rgba(192,192,192)',
            outline: 'rgba(192,192,192)'
        }
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#fff',
        color: '#000',
    }
   
}), { index: 1 });

function MenuActive(path = "", component = "", childComponent = []) {
    let pathSplit = path.split("/").slice(4);
    let compSplit = component.split("/");

    if(pathSplit.length > 1 && pathSplit[pathSplit.length - 1].includes("?")){
        let splitVal =  pathSplit[pathSplit.length - 1].split("?");
        pathSplit[pathSplit.length - 1] = splitVal[0];
    }
   

    if(compSplit.length === 1)
        compSplit = compSplit.filter(e => e !== "");

    if(pathSplit.length === 1)
        pathSplit = pathSplit.filter(e => e !== "");


    if (pathSplit.length !== compSplit.length) {
        if(childComponent.length)
            return ChildComponentCheck(path, childComponent);
        return false;
    }
    for (let i = 0; i < pathSplit.length; i++){
        if(pathSplit[i] !== compSplit[i] && compSplit[i].includes("{") <= 0){
            if(childComponent.length)
                return ChildComponentCheck(path, childComponent);
            return false;
        }
    }
    return true;
}

function ChildComponentCheck(path = "", childComponent = []){
    for(let i = 0; i < childComponent.length; i++){
        if(!MenuActive(path, childComponent[i]))
            continue;
        return true;
    }
    return false;
}

function Layout(props) {
    let [mobileOpen, setMobileOpen] = React.useState(false);
    let [anchorEl, setAnchorEl] = React.useState(null);
    let dispatch = useDispatch();
    let auth = useSelector(state => state.auth);
    let active = useSelector(state => state.active);
    let { children } = props;
    let classes = dashboardStyles();
    let [openNestedMenu, setOpenNestedMenu] = React.useState(false);
    let [openTemplatedMenu, setOpenTemplatedMenu] = React.useState(false);
    let [openConverterMenu, setOpenConverterMenu] = React.useState(false);
    let drawerClasses = useStyles();
    let [open, setOpen] = React.useState(false);
    let [openNestedMenuSubscription, setOpenNestedMenuSubscription] = React.useState(false);
    let [openNestedMenuOptimizer, setOpenNestedMenuOptimizer] = React.useState(false);
    let [directoryLoading, setDirectoryLoading] = React.useState(false);
    let [isCalloutVisible, toggleIsCalloutVisible] = React.useState(false);
    let buttonId = useId('callout-button');
    let labelId = useId('callout-label');
    let descriptionId = useId('callout-description');
    let [selectedTenantId, setSelectTenantId] = React.useState("");
    let [hideDialog, setHideDialog] = React.useState(true);
    let [openSwitchDirectory, setOpenSwitchDirectory] = React.useState(false);
    let [openNestedMenuPolicy, setOpenNestedMenuPolicy] = React.useState(false);
    let [directoryList, setDirectoryList] = React.useState([]);
    let [organizationName, setOrganizationName] = React.useState("");
    let [redirect, setRedirect] = React.useState(false);
    let [openNestedMenuResource, setOpenNestedMenuResource] = React.useState(false);


    let handleClickMenu = () => {
        if (open) {
            setOpenNestedMenu(!openNestedMenu);
        } else{
            setOpenNestedMenu(true);
        }
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuOptimizer(false);
        setOpenNestedMenuPolicy(false);
        setOpenTemplatedMenu(false);
        setOpenConverterMenu(false);
        handleDrawerOpen();
    };

    let handleClickMenuSubscription = () => {
        if (open) {
            setOpenNestedMenuSubscription(!openNestedMenuSubscription);
        } else {
            setOpenNestedMenuSubscription(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuOptimizer(false);
        setOpenNestedMenuPolicy(false);
        setOpenTemplatedMenu(false);
        setOpenConverterMenu(false);
        handleDrawerOpen();
    };

    let handleClickMenuResource = () => {
        if (open) {
            setOpenNestedMenuResource(!openNestedMenuResource);
        } else {
            setOpenNestedMenuResource(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuOptimizer(false);
        setOpenNestedMenuPolicy(false);
        setOpenTemplatedMenu(false);
        setOpenConverterMenu(false);
        handleDrawerOpen();
    };

    let handleClickMenuOptimizer = () => {
        if (open) {
            setOpenNestedMenuOptimizer(!openNestedMenuOptimizer);
        } else {
            setOpenNestedMenuOptimizer(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuPolicy(false);
        setOpenTemplatedMenu(false);
        setOpenConverterMenu(false);
        handleDrawerOpen();
    };

    let handleClickMenuPolicy = () => {
        if (open) {
            setOpenNestedMenuPolicy(!openNestedMenuPolicy);
        } else {
            setOpenNestedMenuPolicy(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuOptimizer(false);
        setOpenTemplatedMenu(false);
        setOpenConverterMenu(false);
        handleDrawerOpen();
    };
    
    let handleClickMenuTemplate = () => {
        if (open) {
            setOpenTemplatedMenu(!openTemplatedMenu);
        } else {
            setOpenTemplatedMenu(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuOptimizer(false);
        setOpenNestedMenuPolicy(false);
        handleDrawerOpen();
    };

    
    let handleClickMenuConverter = () => {
        if (open) {
            setOpenConverterMenu(!openConverterMenu);
        } else {
            setOpenConverterMenu(true);
        }
        setOpenNestedMenu(false);
        setOpenNestedMenuResource(false);
        setOpenNestedMenuSubscription(false);
        setOpenNestedMenuOptimizer(false);
        setOpenNestedMenuPolicy(false);
        setOpenTemplatedMenu(false);
        handleDrawerOpen();
    };


    let handleDrawerOpenClose = () => {
        setOpen(!open);
    };

    let handleDrawerOpen = () => {
        setOpen(true);
    };

    let handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let handleClose = () => {
        setAnchorEl(null);
    };

    function activeBar() {
        let pathSplit = window.location.href.split("/").slice(4);

        if (pathSplit.length > 1 && pathSplit[pathSplit.length - 1].includes("?")) {
            let splitVal = pathSplit[pathSplit.length - 1].split("?");
            pathSplit[pathSplit.length - 1] = splitVal[0];
        }

        let check = pathSplit[1] === undefined ? pathSplit[0] === undefined ? `/` : `/${pathSplit[0]}` : `/${pathSplit[0]}/${pathSplit[1]}`;
       
        switch (check) {
            case '/organization':
            case '/organization/':
                dispatch(ActiveTabActions.updateActiveTab("Organization", check));
                break;
            case '/role':
            case '/role/':
                dispatch(ActiveTabActions.updateActiveTab("Role", check));
                break;
            case '/user':
            case '/user/':
                dispatch(ActiveTabActions.updateActiveTab("User", check));
                break;
            case '/model':
            case '/model/':
                dispatch(ActiveTabActions.updateActiveTab("Blueprint Management", check));
                break;
            case '/image':
            case '/image/':
                dispatch(ActiveTabActions.updateActiveTab("Image", check));
                break;
            case '/extension':
            case '/extension/':
                dispatch(ActiveTabActions.updateActiveTab("Extension", check));
                break;
            case '/subscription':
            case '/subscription/':
                dispatch(ActiveTabActions.updateActiveTab("Subscription", check));
                break;
            case '/insights':
            case '/insights/':
                dispatch(ActiveTabActions.updateActiveTab("Insights", check));
                break;
            case '/subscription/resourceGroup':
            case '/subscription/resourceGroup/':
                dispatch(ActiveTabActions.updateActiveTab("Resource Group", check));
                break;
            case '/costanalysis':
            case '/costanalysis/':
                dispatch(ActiveTabActions.updateActiveTab("Cost Analysis", check));
                break;
            case '/vm':
            case '/vm/':
                dispatch(ActiveTabActions.updateActiveTab("Virtual Machine List", check));
                break;
            case "":
            case "/":
                dispatch(ActiveTabActions.updateActiveTab("Blueprints", check));
                break;
            case '/deployment':
            case '/deployment/':
                dispatch(ActiveTabActions.updateActiveTab("Deployment List", check));
                break;
            case '/deployment/subscription':
            case '/deployment/subscription/':
                dispatch(ActiveTabActions.updateActiveTab("Resource Management", '/deployment'));
                break;

            case '/pbiDashboard':
            case '/pbiDashboard/':
            case '/pbiDashboardView':
            case '/pbiDashboardView/':
                dispatch(ActiveTabActions.updateActiveTab("PBI Dashboard", '/pbiDashboard'));
                break;
            case '/explore-database':
            case '/explore-database/':
            case '/explore-database-view/':
            case '/explore-database-view':
                dispatch(ActiveTabActions.updateActiveTab("Explore Data", '/explore-database'));
                break;
            case '/aws/s3bucket':
            case '/aws/s3bucket/':
            case '/aws/s3bucket-details':
            case '/aws/s3bucket-details/':
                dispatch(ActiveTabActions.updateActiveTab("S3 Bucket", '/aws/s3bucket'));
                break;
            case '/storageAccount':
            case '/storageAccount/':
            case '/storageAccountExplore/subscriptions':
            case '/storageAccountExplore/subscriptions/':
                dispatch(ActiveTabActions.updateActiveTab("Storage", '/storageAccount'));
                break;
            case '/functionApp':
            case '/functionApp/':
            case '/functionAppExplore/subscriptions':
            case '/functionAppExplore/subscriptions/':
                dispatch(ActiveTabActions.updateActiveTab("Function App", '/functionApp'));
                break;
            case '/learn':
            case '/learn/':
            case '/learndetails':
            case '/learndetails/':
                dispatch(ActiveTabActions.updateActiveTab("Learn", '/learn'));
                break;
            case '/form':
            case '/form/':
                dispatch(ActiveTabActions.updateActiveTab("Form", '/form'));
                break;
            case '/optimizer/cost':
            case '/optimizer/cost/':
                dispatch(ActiveTabActions.updateActiveTab("Cost Optimizer", '/optimizer/cost'));
                break;
            case '/optimizer/security':
            case '/optimizer/security/':
                dispatch(ActiveTabActions.updateActiveTab("Optimizer - Security", '/optimizer/security'));
                break;
            case '/optimizer/highAvailability':
            case '/optimizer/highAvailability/':
                dispatch(ActiveTabActions.updateActiveTab("Optimizer - High Availability", '/optimizer/highAvailability'));
                break;
            case '/optimizer/operationalExcellence':
            case '/optimizer/operationalExcellence/':
                dispatch(ActiveTabActions.updateActiveTab("Optimizer - Operational Excellence", '/optimizer/operationalExcellence'));
                break;
            case '/optimizer/performance':
            case '/optimizer/performance/':
                dispatch(ActiveTabActions.updateActiveTab("Optimizer - Performance", '/optimizer/performance'));
                break;
            case '/policy/assignments':
            case '/policy/assignments/':
                dispatch(ActiveTabActions.updateActiveTab("Policy Assign", "/policy/assignments"));
                break;
            case '/policy/resources':
            case '/policy/resources/':
                dispatch(ActiveTabActions.updateActiveTab("Compliance Resources", "/policy/resources"));
                break;
            case '/template/field':
            case '/template/field/':
                dispatch(ActiveTabActions.updateActiveTab("Template Field", "/template/field"));
                break;
            case '/template/form':
            case '/template/form/':
                dispatch(ActiveTabActions.updateActiveTab("Template Form", "/template/form"));
                break;
            case '/template/type':
            case '/template/type/':
                dispatch(ActiveTabActions.updateActiveTab("Template Type", "/template/type"));
                break;
            case '/converter/workspace':
            case '/converter/workspace/':
                dispatch(ActiveTabActions.updateActiveTab("Converter Workspace", "/converter/workspace"));
                break;
            case '/converter/saved':
            case '/converter/saved/':
                dispatch(ActiveTabActions.updateActiveTab("Converter Saved List", "/converter/saved"));
                break;
            case '/website-settings':
            case '/website-settings/':
                dispatch(ActiveTabActions.updateActiveTab("Website Settings", "/website-settings"));
                break;
            default:
                dispatch(ActiveTabActions.updateActiveTab("Blueprints", "/"));
                break;
        }
    }

    window.onload = () => {
        activeBar();
    };

    useEffect(() => {
        document.title = active.activeTab === "" ? 'ManageX' : `${active.activeTab} - ManageX`;
    }, [active]);

    useEffect(() => {
        setDirectoryLoading(true);
        activeBar();
        setDirectoryList([{ key: "Loading", text: "Loading..." }])
        DirectoryService.GetDirectory().then(res => {
            let list = [];
            res.data.value.forEach(data => {
                list.push({ key: data.tenantId, text: data.displayName });
            });
            setDirectoryLoading(false);
            setDirectoryList(list)
        }).catch(err => {
            setDirectoryLoading(false);
            if (err.toString().includes('401')) {
                dispatch(AuthActions.checkToken(window.location.pathname))
            }
        })

        OrgService.GetDataByUsingKey(auth.account.orgId).then(res => {
            setOrganizationName(res.data.name);
        });

    }, [dispatch, auth.account.orgId]);


    const menuItems = [
        {
            name: "Administration",
            icon: "AccountManagement",
            open: openNestedMenu,
            function: handleClickMenu,
            permission: ["organizationList", "roleList", "userList", "blueprintList", "imageList", "extensionList", "blueprintTypeList"],
            children: [
                { name: "Organization", icon: "CityNext", permission: "organizationList", url: ["/organization", "/organization/"], tab: "Organization", to: "/organization" },
                { name: "User", icon: "Contact", permission: "userList", url: ["/user", "/user/"], tab: "User", to: "/user" },
                { name: "Role", icon: "ContactCardSettings", permission: "roleList", url: ["/role", "/role/"], tab: "Role", to: "/role" },
                { name: "Blueprint Management", icon: "TextDocument", permission: "blueprintList", url: ["/model", "/model/"], tab: "Blueprint Management", to: "/model" },
                { name: "Image", icon: "Photo2", permission: "imageList", url: ["/image", "/image/"], tab: "Image", to: "/image" },
                { name: "Extensions", icon: "Puzzle", permission: "extensionList", url: ["/extension", "/extension/"], tab: "Extension", to: "/extension" }
            ]
        },
        {
            name: "Template",
            icon: "AssessmentGroupTemplate",
            open: openTemplatedMenu,
            function: handleClickMenuTemplate,
            permission: ["templateFieldList", "templateFormList", "templateTypeList"],
            children: [
                { name: "Field", icon: "FieldFilled", permission: "templateFieldList", url: ["/template/field", "/template/field/"], tab: "Template Field", to: "/template/field" },
                { name: "Form", icon: "FormLibrary", permission: "templateFormList", url: ["/template/form", "/template/form/"], tab: "Template Form", to: "/template/form" },
                { name: "Type", icon: "Page", permission: "templateTypeList", url: ["/template/type", "/template/type/"], tab: "Template Type", to: "/template/type" }
            ]
        },
        {
            name: "Subscription",
            icon: "AzureKeyVault",
            open: openNestedMenuSubscription,
            function: handleClickMenuSubscription,
            permission: ["subscriptionList", "costAnalysis", "resourceGroupList", "insightsService"],
            children: [
                { name: "Subscription List", icon: "Permissions", permission: "subscriptionList", url: ["/subscription", "/subscription/"], tab: "Subscription", to: "/subscription" },
                { name: "Resource Group", icon: "GroupObject", permission: "resourceGroupList", url: ["/subscription/resourceGroup", "/subscription/resourceGroup/"], tab: "Resource Group", to: "/subscription/resourceGroup?sids=All" },
                { name: "Cost Analysis", icon: "AnalyticsReport", permission: "costAnalysis", url: ["/costanalysis", "/costanalysis/"], tab: "Cost Analysis", to: "/costanalysis" },
                { name: "Metrics", icon: "BIDashboard", permission: "metrics", url: ["/metrics", "/metrics/"], tab: "Metrics", to: "/metrics" },
                { name: "Insights", icon: "AnalyticsLogo", permission: "insightsService", url: ["/insights", "/insights/"], tab: "Insights", to: "/insights" },
            ]
        },
        {
            name: "Policy",
            icon: "ComplianceAudit",
            open: openNestedMenuPolicy,
            function: handleClickMenuPolicy,
            permission: ["policyAssign" ,"complianceResource"],
            children: [
                { name: "Policy Assign", icon: "EntitlementPolicy", permission: "policyAssign", url: ["/policy/assignments", "/policy/assignments/"], tab: "Policy Assign", to: "/policy/assignments" },
                { name: "Compliance Resource", icon: "ComplianceAudit", permission: "complianceResource", url: ["/policy/resources", "/policy/resources/"], tab: "Compliance Resource", to: "/policy/resources" },
            ]
        },
        {
            name: "Optimizer",
            icon: "CRMReport",
            open: openNestedMenuOptimizer,
            function: handleClickMenuOptimizer,
            permission: ["costRecommendation", "securityRecommendation", "highAvailabilityRecommendation", "operationalExcellenceRecommendation", "performanceRecommendation"],
            children: [
                { name: "Cost", icon: "Chart", permission: "costRecommendation", url: ["/optimizer/cost", "/optimizer/cost/"], tab: "Cost Optimizer", to: "/optimizer/cost" },
                { name: "Security", icon: "Shield", permission: "securityRecommendation", url: ["/optimizer/security", "/optimizer/security/"], tab: "Optimizer - Security", to: "/optimizer/security" },
                { name: "High Availability", icon: "WebComponents", permission: "highAvailabilityRecommendation", url: ["/optimizer/highAvailability", "/optimizer/highAvailability/"], tab: "Optimizer - High Availability", to: "/optimizer/highAvailability" },
                { name: "Operational Excellence", icon: "Badge", permission: "operationalExcellenceRecommendation", url: ["/optimizer/operationalExcellence", "/optimizer/operationalExcellence/"], tab: "Optimizer - Operational Excellence", to: "/optimizer/operationalExcellence" },
                { name: "Performance", icon: "Running", permission: "performanceRecommendation", url: ["/optimizer/performance", "/optimizer/performance/"], tab: "Optimizer - Performance", to: "/optimizer/performance" },
            ]
        },
        {
            name: "Resource",
            icon: "PageData",
            open: openNestedMenuResource,
            function: handleClickMenuResource,
            permission: ["s3BucketList", "powerBiDashboardList", "exploreDataList", "portalVmList", "azureVmList", "storageAccountList"],
            children: [
                { name: "Power BI", icon: "PowerBILogo", permission: "powerBiDashboardList", url: ["/pbiDashboard", "/pbiDashboard/"], tab: "PBI Dashboard", to: "/pbiDashboard" },
                { name: "Explore Data", icon: "ExploreData", permission: "exploreDataList", url: ["/explore-database", "/explore-database/"], tab: "Explore Data", to: "/explore-database" },
                { name: "Form", icon: "FormLibrary", permission: "formList", url: ["/form", "/form/"], tab: "Form", to: "/form" },
                { name: "Virtual Machine", icon: "Devices3", permission: "portalVmList", url: ["/vm", "/vm/"], tab: "Virtual Machine List", to: "/vm" },
                { name: "S3 Bucket", icon: "TFVCLogo", permission: "s3BucketList", provider: "aws-auth", url: ["/aws/s3bucket", "/aws/s3bucket/"], tab: "S3 Bucket", to: "/aws/s3bucket" },
                { name: "Storage Account", icon: "Database", permission: "storageAccountList", url: ["/storageAccount", "/storageAccount/"], tab: "Storage", to: "/storageAccount" },
                { name: "Function App", icon: "WebAppBuilderFragment", permission: "functionAppList", url: ["/functionApp", "/functionApp/"], tab: "Function App", to: "/functionApp" },
            ]
        },
        {
            name: "Blueprints",
            icon: "TextDocument",
            permission: ["blueprints"],
            tab: "Blueprints",
            to: "/",
            url: ["/", ""],
        },
        {
            name: "Deployments",
            icon: "Deploy",
            permission: ["deploymentList"],
            tab: "Deployment List",
            to: "/deployment",
            url: ["/deployment", "/deployment/"],
        },
        {
            name: "CMS",
            icon: "ContactCardSettings",
            permission: ["cms"],
            tab: "Content Management System",
            to: "/cms/pageslist",
            url: ["/cms", "/cms/"],
        },
        {
            name: "Learn",
            icon: "ReadingMode",
            permission: ["learnList"],
            tab: "Learn",
            to: "/learn",
            url: ["/learn", "/learn/"],
        },
        {
            name: "Convertor",
            icon: "NoteForward",
            open: openConverterMenu,
            function: handleClickMenuConverter,
            permission: ["converter", "convertedList"],
            children: [
                { name: "Workspace", icon: "HighlightMappedShapes", permission: "converter", url: ["/converter/workspace", "/converter/workspace/"], tab: "Converter Workspace", to: "/converter/workspace" },
                { name: "List", icon: "SaveAndPublish", permission: "convertedList", url: ["/converter/saved", "/converter/saved/"], tab: "Converter Saved List", to: "/converter/saved" },
            ]
        },
        {
            name: "Website Settings",
            icon: "PlayerSettings",
            permission: ["websiteSettings"],
            tab: "Website Settings",
            to: "/website-settings",
            url: ["/website-settings", "/website-settings/"],
        }
    ];
   

    let list = <List style={{ overflow: 'auto', overflowX: 'hidden' }}>
        {
            menuItems.map((data, index) => {
                return data.children?.length > 0 ?
                    <span key={index}>
                        {
                            data.permission.map(dataVal => { return PermissionCheck(dataVal) }).findIndex(val => val === true) >= 0 && (auth.provider === data?.provider || data.name === "Resource"  )?
                                <Tooltip title={open ? "" : <Text variant="small">{data.name}</Text>}>
                                    <ListItem button onClick={data.function}>
                                        <ListItemIcon>
                                            <FontIcon iconName={data.icon} style={{ color: "#14E2C5", marginLeft: 10, fontSize: 20 }} />
                                        </ListItemIcon>
                                        <Stack horizontal horizontalAlign="space-between" style={{ width: "100%", paddingTop: 7 }}>
                                            <Stack.Item >
                                                <Text styles={{ root: { fontSize: '14px', fontWeight: 600, color: "#14E2C5" } }} >{data.name}</Text>
                                            </Stack.Item>
                                            <Stack.Item >
                                                {data.open ? <ExpandLess style={{ color: "#14E2C5" }} /> : <ExpandMore style={{ color: "#14E2C5" }} />}
                                            </Stack.Item>
                                        </Stack>

                                    </ListItem>
                                </Tooltip>
                                : ""
                        }
                        <Collapse in={data.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    data.children.map((childrenData, idx) => {
                                        return PermissionCheck(childrenData.permission) && open && auth.provider === childrenData?.provider ?
                                            <Tooltip title={open ? "" : <Text variant="small">{childrenData.name}</Text>} key={idx}>
                                                <ListItem 
                                                    button 
                                                    style={{
                                                        width: "100%",
                                                        backgroundImage: childrenData.url.map(val => { return active.activeURL === val }).findIndex(value => value === true) >= 0 ? `url(${DrawerActiveBar})` : null,
                                                        backgroundPosition: "50% 30%",
                                                        backgroundSize: "245px 50px",
                                                        backgroundRepeat: "no-repeat"
                                                    }}
                                                    component={Link} onClick={() => {
                                                        dispatch(ActiveTabActions.updateActiveTab(childrenData.tab, childrenData.url[0]));
                                                    }} 
                                                    to={childrenData.to} 
                                                    selected={childrenData.url.map(val => { return active.activeURL === val }).findIndex(value => value === true) >= 0}>
                                                        <ListItemIcon>
                                                            <FontIcon iconName={childrenData.icon} style={{ color: 'white', marginLeft: 15, fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <Text styles={{ root: { fontSize: '13px' } }} >{childrenData.name}</Text>
                                                </ListItem>
                                            </Tooltip>
                                            : ""
                                    })

                                }
                            </List>
                        </Collapse>
                    </span>
                    :
                    <span key={ index}>
                      { 
                         data.permission.map(dataVal => { return PermissionCheck(dataVal) }).findIndex(val => val === true) >= 0 && auth.provider === data?.provider ?
                              <Tooltip title={open ? "" : <Text variant="small">{data.name}</Text>}>
                                  <ListItem button style={{
                                      width: "100%",
                                      backgroundImage: data.url.map(val => { return active.activeURL === val }).findIndex(value => value === true) >= 0 ? `url(${DrawerActiveBar})` : null,
                                      backgroundPosition: "50% 30%",
                                      backgroundSize: "245px 50px",
                                      backgroundRepeat: "no-repeat"
                                  }}
                                      component={Link} to={data.to} onClick={() => { dispatch(ActiveTabActions.updateActiveTab(data.tab, data.url[0])); }} selected={data.url.map(val => { return active.activeURL === val }).findIndex(value => value === true) >= 0 }>
                                      
                                      <ListItemIcon >
                                          <FontIcon iconName={data.icon} style={{ fontSize: 18, marginLeft: 10, color: "#14E2C5" }} />
                                      </ListItemIcon>
                                      <Text styles={{ root: { fontSize: '14px', fontWeight: 600, color: "#14E2C5" } }}>{data.name}</Text>
                                  </ListItem>
                              </Tooltip> : ""
                      }
                  </span>                    
            })
        }
        </List>

    function _onRenderSecondaryText() {
        return (
            directoryLoading ?
                <Spinner styles={{ root: { textAlign: 'left' } }} size={SpinnerSize.small} label="Fetching...." ariaLive="assertive" labelPosition="right" />
                :
                auth.tenantId !== undefined && auth.tenantId !== "" ?     
                    <div>
                        <Text variant="small">
                            {directoryList?.[directoryList.findIndex(data => data.key === auth.tenantId)]?.['text']}
                        </Text>
                </div>:<div><Text>--</Text></div>
        );
    }


    return (
        <div style={{ overflow: 'hidden' }} >
            <CssBaseline />
            <div className={classes.root}>

                {/* Directory Dialog */}
                <Suspense fallback={
                    <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Loading...."
                             ariaLive="assertive" labelPosition="right" />} >
                    <DirectoryDialog
                        dispatch={dispatch} hideDialog={hideDialog} setHideDialog={setHideDialog}
                        selectedTenantId={selectedTenantId}
                    />
                </Suspense>

                {/* TopBar */}
                <Suspense fallback={
                    <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Loading...."
                             ariaLive="assertive" labelPosition="right" />} >
                    <TopBar
                        classes={classes} buttonId={buttonId} labelId={labelId} descriptionId={descriptionId} auth={auth}
                        handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} isCalloutVisible={isCalloutVisible}
                        toggleIsCalloutVisible={toggleIsCalloutVisible} organizationName={organizationName} dispatch={dispatch}
                        directoryLoading={directoryLoading} directoryList={directoryList} anchorEl={anchorEl} handleClose={handleClose}
                        drawerClasses={drawerClasses} selectedTenantId={selectedTenantId} setHideDialog={setHideDialog}
                        setSelectTenantId={setSelectTenantId} _onRenderSecondaryText={_onRenderSecondaryText} width={props.width}
                        list={list} switchDirectory={openSwitchDirectory} setSwitchDirectory={setOpenSwitchDirectory} setRedirect={setRedirect}
                        redirect={redirect}
                    />
                </Suspense>

                {/* Main Section */}
                <Suspense fallback={
                    <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Loading...."
                             ariaLive="assertive" labelPosition="right" />} >
                    <MainSection
                        classes={classes} handleDrawerOpenClose={handleDrawerOpenClose} open={open} list={list}
                        children={children} width={props.width}
                    />
                </Suspense>

            </div>
        </div>
    );
}
export default withWidth()(Layout);