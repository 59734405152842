import { AppSettingsState } from "./reducer";

const updateAppSettings = (appSettings) => {
    const success = (result) => {
        return {
            type: AppSettingsState.UPDATE_APP_SETTINGS,
            data: result
        }
    }
    return (dispatch) => {
        let data = {
            type: AppSettingsState.UPDATE_APP_SETTINGS,
            ...appSettings
        }
        dispatch(success(data))
    }
}

export const AppSettingsActions = {
    updateAppSettings
}