import {ListQuery, Endpoint, Service} from "../../const";

//Organization Actions

const endpoint = Endpoint.Organization;
const mailEndpoint = Endpoint.MailTemplate;
export const OrgService = {
    GetAllData : (data = ListQuery, token = "") => {
        let query = (data.filter ? "filter=" + data.filter : "")
            + (data.skip ? "&skip=" + data.skip : "")
            + (data.limit ? "&limit=" + data.limit : "")
            + (data.from ? "&from=" + data.from : "")
            + (data.to ? "&to=" + data.to : "")
            + (data.fields ? "&fields=" + data.fields : "")
            +( data.sortObj ? "&sortObj=" + encodeURI(JSON.stringify(data.sortObj)) : "")
            + (data.filterObj ? "&filterObj=" + encodeURI(JSON.stringify(data.filterObj)) : "");
        return Service.Request.Get(endpoint + (query !== "" ? ("?" + query) : "") ,
            token
        )
    },
    CreateData: (data, token = "") => Service.Request.Post(endpoint, data, token),
    CreateDataWithUser: (data, token = "") => Service.Request.Post(endpoint +"withuser", data, token),
    UpdateData : (id, data, token = "") => Service.Request.Put(endpoint+ "/" + id ,data, token),
    GetDataByUsingId: (id, token = "") => Service.Request.Get(endpoint+ "/" + id, token),
    GetDataByUsingKey: (key, token = "") => Service.Request.Get(endpoint+ "/key/" + key, token),
    DeleteData: (id, token = "") => Service.Request.Delete(endpoint+ "/"+ id, token),
    GetMsOrg: (data, token = "") => Service.Request.Post("/api/azure/organization.get", data, token),
    GetMailTemplate: (orgId, token = "") => Service.Request.Get(mailEndpoint + "?orgId=" + orgId, token),
    UpdateMailTemplate: (id, data, token="") => Service.Request.Put(mailEndpoint+"/"+id,data,token),
}
