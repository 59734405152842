import React, {lazy , Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AuthState} from "../modules/redux/Auth/reducer";
import {AuthActions} from "../modules/redux";
import { PermissionCheck } from "../const";
import { dashboardStyles } from "../styles";
import {Spinner, SpinnerSize} from "office-ui-fabric-react/lib/Spinner";

const TokenExpired = lazy(() => import("../components/Authentication/TokenExpired"));
const PermissionDenied = lazy(() => import("../components/Authentication/PermissionDenied"));

const PrivateRoute = ({component: Component, componentName, ...rest}) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const classes = dashboardStyles();
    const [next, setNext] = React.useState(false);

    if (next) {
        setNext(false);
        dispatch(AuthActions.logout("",auth.latestURLPath))
    }

    return (
        <Route {...rest} render={
            (props) => {
                switch (auth.state)
                {
                    case AuthState.NOT_LOGGED_IN:
                    case AuthState.LOGIN_REQUEST:
                    case AuthState.LOGIN_ERROR:
                    case AuthState.SET_TOKEN:
                    case AuthState.SWITCH_DIRECTORY:
                    case AuthState.TOKEN_EXPIRED:
                        return <Redirect to={{pathname: '/', state: {from: props.location}}}/>;
                    // case AuthState.TOKEN_EXPIRED:
                    //     return <Suspense fallback={
                    //         <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Fetching...."
                    //                  ariaLive="assertive" labelPosition="right" />} >
                    //         <TokenExpired classes={classes} setNext={setNext}/>
                    //     </Suspense>;
                    default:
                        if ((componentName === 'vmList' && (PermissionCheck('azureVmList', dispatch)
                            || PermissionCheck('portalVmList', dispatch)))
                            || PermissionCheck(componentName, dispatch) || !componentName)
                        {
                            return <Suspense fallback={
                                <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Fetching...."
                                         ariaLive="assertive" labelPosition="right" />} >
                                <Component {...props} />
                            </Suspense>;
                        } else {
                            return <Suspense fallback={
                                <Spinner styles={{ root: { marginTop: '5px' } }} size={SpinnerSize.medium} label="Fetching...."
                                         ariaLive="assertive" labelPosition="right" />} >
                                <PermissionDenied classes={classes}/>
                            </Suspense>
                        }
                }
            }
        }
        />
    );
};

export default PrivateRoute;