import React, { Component, lazy } from "react";
import { BrowserRouter, Switch} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Theme } from "../styles";
import PrivateRoute from "../router/privateRoute";
import ErrorBoundary from "./errorBoundary";

import Layout from "./Layout";

let Blueprint = lazy(() => import("./Blueprint"));
let history = lazy(() => import("../history"));
let DeploymentList =  lazy(() => import("./DeploymentList"));
let ContentManagmentSystem = lazy(()=> import("./ContentManagmentSystem"));
let ResourceManagement = lazy(() => import("./ResourceManagement"));
let Help = lazy(() => import("./Help"));
let CostManagement = lazy(() => import("./CostManagement"));
let PowerBIDashboard = lazy(() => import("./PowerBIDashboard"));
let BluePrintDetails = lazy(() => import("./BluePrintDetails"));
let Organization = lazy(() => import("./Organization"));
let User = lazy(() => import("./User"));
let Role = lazy(() => import("./Role"));
let Model = lazy(() => import("./Model"));
let VmImage = lazy(() => import("./VmImages"));
let DeploymentForm = lazy(() => import("./DeploymentForm"));
let Extension = lazy(() => import("./Extensions"));
let Subscription = lazy(() => import("./Subscription"));
let InsightsService = lazy(() => import("./InsightsService"));
let Metrics = lazy(() => import("./Metrics"));
let ResourceGroup = lazy(() => import("./ResourceGroup"));
let Vm = lazy(() => import("./Vm"));
let Preview = lazy(() => import("./Preview"));
let PowerBIDashboardView = lazy(() => import("./PowerBiDashboardView"));
let ExploreData = lazy(() => import("./ExploreData"));
let CreateDatabase = lazy(() => import("./ExploreDatabase"));
let ProfilePage = lazy(() => import("./ProfilePage"));
let S3Bucket = lazy(() => import("./S3Bucket"));
let S3BucketDetails = lazy(() => import("./S3BucketDetails"));
let StorageAccount = lazy(() => import("./StorageAccount"));
let StorageAccountExplore = lazy(() => import("./StorageAccountExplore"));
let FunctionApp = lazy(() => import("./FunctionApp"));
let Function = lazy(() => import("./FunctionApp/Functions"));
let Learn = lazy(() => import("./Learn"));
let LearnDetails = lazy(() => import("./Learn/LearnDetails"));
let Form = lazy(() => import("./Form"));

// Optimizer
let Cost = lazy(() => import("./Optimizer/Cost"));
// let CostRecommendationDetailList = lazy(() => import("./Optimizer/Cost/RecommendationDetailList"));
let HighAvailability = lazy(() => import("./Optimizer/HighAvailability"));
let OperationalExcellence = lazy(() => import("./Optimizer/OperationalExcellence"));
let Performance = lazy(() => import("./Optimizer/Performance"));
let Security = lazy(() => import("./Optimizer/Security"));

// Policy
let PolicyDefinition = lazy(() => import("./PolicyDefinition"));
let PolicyReport = lazy(() => import("./PolicyReport"));
let PolicyAssign = lazy(() => import("./PolicyAssign"));
let ComplianceResources = lazy(() => import("./ComplianceResources"));

// Template
let TemplateType = lazy(() => import("./TemplateType"));
let TemplateField = lazy(() => import("./TemplateField"));
let TemplateForm = lazy(() => import("./TemplateForm"));


let Converter = lazy(() => import("./Converter"));
let ConvertedList = lazy(() => import("./Converter/ConvertedList"));

let WebSettings = lazy(() => import("./WebSettings"));


class EduResearchPortal extends Component{
    render() {
        return (
            <ThemeProvider theme={Theme}>
                <BrowserRouter basename="/portal">
                    <Layout>
                        <ErrorBoundary>
                            <Switch history={history}>
                                <PrivateRoute exact path="/" component={Blueprint} componentName={"blueprints"} />
                                <PrivateRoute exact path="/blueprint/:name" component={BluePrintDetails} componentName={"blueprints"} />
                                <PrivateRoute exact path="/blueprint/:name/form" component={DeploymentForm} componentName={"blueprintProvision"} />
                                <PrivateRoute path="/help" component={Help} componentName={"help"} />
                                <PrivateRoute exact path="/subscription/:sid/resourceGroup" component={ResourceGroup} componentName={"resourceGroupList"} />
                                <PrivateRoute exact path="/subscription/resourceGroup" component={ResourceGroup} componentName={"resourceGroupList"} />
                                
                                <PrivateRoute exact path="/deployment/subscription/:sid/resourcegroup/:rid/deploymentname/:dname" component={ResourceManagement} componentName={"resourceManagement"} />
                                <PrivateRoute exact path="/deployment/subscription/:sid/deploymentname/:dname" component={ResourceManagement} componentName={"resourceManagement"} />
                                <PrivateRoute path="/deployment" component={DeploymentList} componentName={"deploymentList"}/>
                                <PrivateRoute path="/contentmanagementsystem/:id" component={ContentManagmentSystem} componentName={"contentmanagementsystem"} />
                                <PrivateRoute path="/costanalysis" component={CostManagement} componentName={"costAnalysis"}/>
                                <PrivateRoute path="/pbiDashboard" component={PowerBIDashboard} componentName={"powerBiDashboardList"} />
                                <PrivateRoute path="/pbiDashboardView/:id" component={PowerBIDashboardView} componentName={"powerBiDashboardView"} />

                                <PrivateRoute path="/organization" component={Organization} componentName={"organizationList"}/>
                                <PrivateRoute path="/user" component={User} componentName={"userList"}/>
                                <PrivateRoute path="/role" component={Role} componentName={"roleList"}/>
                                <PrivateRoute path="/model" component={Model} componentName={"blueprintList"}/>
                                <PrivateRoute path="/image" component={VmImage} componentName={"imageList"}/>
                                <PrivateRoute path="/extension" component={Extension} componentName={"extensionList"} />
                                <PrivateRoute exact path="/subscription" component={Subscription} componentName={"subscriptionList"} />
                                <PrivateRoute path="/insights" component={InsightsService} componentName={"insightsService"} />
                                <PrivateRoute path="/metrics" component={Metrics} componentName={"metrics"} />
                                <PrivateRoute path="/vm" component={Vm} componentName={"vmList"} />
                                <PrivateRoute path="/preview" component={Preview} componentName={"mailTemplatePreview"} />
                                <PrivateRoute path="/explore-database" component={CreateDatabase} componentName={"exploreDataList"} />
                                <PrivateRoute path="/explore-database-view/:id" component={ExploreData} componentName={"exploreDataView"} />
                                <PrivateRoute path="/profile" component={ProfilePage} componentName={"blueprints"} />
                                <PrivateRoute path="/aws/s3bucket" component={S3Bucket} componentName={"s3BucketList"} />
                                <PrivateRoute path="/aws/s3bucket-details/:name" component={S3BucketDetails} componentName={"s3BucketList"} />
                                <PrivateRoute path="/storageAccount" component={StorageAccount} componentName={"storageAccountList"} />
                                <PrivateRoute path="/functionApp" component={FunctionApp} componentName={"functionAppList"} />
                                <PrivateRoute path="/functionAppExplore/subscriptions/:id/resourceGroups/:name/providers/Microsoft.Web/sites/:name" component={Function} componentName={"functionAppList"} />
                                <PrivateRoute path="/storageAccountExplore/subscriptions/:id/resourceGroups/:name/providers/Microsoft.Storage/storageAccounts/:name" component={StorageAccountExplore} componentName={"storageAccountList"} />
                                <PrivateRoute path="/learn" component={Learn} componentName={"learnList"} />
                                <PrivateRoute exact path="/learndetails/:id" component={LearnDetails} componentName={"learnView"} />
                                <PrivateRoute path="/form" component={Form} componentName={"formList"} />
                                
                                {/*  Optimizer  */}
                                <PrivateRoute path="/optimizer/cost" component={Cost} componentName={"costRecommendation"} />
                                <PrivateRoute path="/optimizer/security" component={Security} componentName={"securityRecommendation"} />
                                <PrivateRoute path="/optimizer/highAvailability" component={HighAvailability} componentName={"highAvailabilityRecommendation"} />
                                <PrivateRoute path="/optimizer/operationalExcellence" component={OperationalExcellence} componentName={"operationalExcellenceRecommendation"} />
                                <PrivateRoute path="/optimizer/performance" component={Performance} componentName={"performanceRecommendation"} />
                                
                                {/*  Policy  */}
                                <PrivateRoute exact path="/policy/definitions/:complianceId" component={PolicyDefinition} componentName={"policyDefinition"} />
                                <PrivateRoute exact path="/policy/report/subscriptions/:subscriptionId/blueprints/:blueprintId/deployments/:deploymentId" component={PolicyReport} componentName={"policyReport"} />
                                <PrivateRoute exact path="/policy/assignments" component={PolicyAssign} componentName={"policyAssign"} />
                                <PrivateRoute exact path="/policy/resources" component={ComplianceResources} componentName={"complianceResource"} />
                                
                                {/*  Template  */}
                                <PrivateRoute path="/template/type" component={TemplateType} componentName={"templateTypeList"} />
                                <PrivateRoute path="/template/field" component={TemplateField} componentName={"templateFieldList"} />
                                <PrivateRoute path="/template/form" component={TemplateForm} componentName={"templateFormList"} />
                                
                                {/* Convertor */}
                                <PrivateRoute path="/converter/workspace" component={Converter} componentName={"converter"} />
                                <PrivateRoute path="/converter/saved" component={ConvertedList} componentName={"convertedList"}/>
                                
                                {/*  Web Settings  */}
                                <PrivateRoute path="/website-settings" component={WebSettings} componentName={"websiteSettings"}/>
                                
                            </Switch>
                        </ErrorBoundary>
                    </Layout>
                </BrowserRouter>
            </ThemeProvider>
        );
    }
}

export default EduResearchPortal;
