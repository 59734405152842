export const AppSettingsState = {
    UPDATE_APP_SETTINGS:"UPDATE_APP_SETTINGS"
};

const data = {
    msService: "general"
};

// App Settings Reducer
export default function appSettingsReducer (state = data, action) {
    switch (action.type) {
        case AppSettingsState.UPDATE_APP_SETTINGS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};
