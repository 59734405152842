import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  body: {
    "*::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em"
    },
    
    "*::-webkit-scrollbar-track": {
      WebkitBorderRadius: 10,
      borderRadius: 10,
      transition: "background-color 0.75s ease",
      "&:hover": {
        backgroundColor: "rgba(240, 240, 240, 0.2)"
      }
    },
    "*::-webkit-scrollbar-thumb": {
      WebkitBorderRadius: 10,
      borderRadius: 10,
      backgroundColor: "transparent",
      transition: "background-color 0.5s ease",
      "&:hover": {
        backgroundColor: "rgb(149,149,149)"
      },
      "&:active": {
        backgroundColor: "rgb(192, 192, 192)"
      }
    },
    "*::-webkit-scrollbar-thumb:vertical": {
      transition: "background-color 0.5s ease"
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(192, 192, 192)"
    },
    fontSize: 14,
    "@media (min-width: 768px)": {
      fontSize: 16
    },
    fontFamily: [
      // 'poppins',
      //'sans-serif'
    ].join(",")
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "inherit",
    textmd : {
      fontSize : '14px',
      lineHeight : 'normal',
    },
    titlesm : {
      fontSize : '16px',
      lineHeight : 'normal'
    },
    titlemd : {
      fontSize : '24px',
      lineHeight : 'normal'
    }
  },
  palette: {
    primary: {
      main: "#1A73E7"
      // main: "#a01315",
      // mainGradient: "linear-gradient(45deg, #211e20 30%, #a01315 90%)",
    },
    secondary: {
      // main: "#000000",
      main: "#fff"
    }
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "30px",
        fontWeight: "500"
      }
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#424e58",
          // borderRadius: "30px 0px 0px 30px",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#424e58"
            // borderRadius: "30px 0px 0px 30px",
          }
        }
      },
      button: {
        borderRadius: "5px 5px 5px 5px",
        "&:hover": {
          backgroundColor: "#424e58",
          color: "#fff"
        }
      }
    }
  }
});

export const Theme = theme;
