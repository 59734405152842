import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import EduResearch from './eduResearch';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter>
        <EduResearch />
    </BrowserRouter>, document.getElementById('root')
);

serviceWorker.unregister();
